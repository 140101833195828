import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TradeState } from 'utils/blockchain/real/contracts/p2pTrading'
import getShortText from 'utils/js/getShortText'
import useCounterTrades from './useCounterTrades'
import useTrades from './useTrades'

export default function useNotifyCounterTrades() {
	const wallet = useWallet()
	const myAddress = wallet.wallets[0]?.terraAddress
	const history = useHistory()

	const [trades] = useTrades({
		states: [
			TradeState.Countered,
			TradeState.Accepted,
			TradeState.Published,
			TradeState.Cancelled,
		],
		counterer: myAddress ?? '-',
	})

	const [counterTrades] = useCounterTrades({
		owner: myAddress ?? '-',
	})

	const notifyCounters = () => {
		const cancelledTrades = trades.filter(
			trade => trade.state === TradeState.Cancelled.toLowerCase()
		)

		// My funds are left in this cancelled listings. Notify me.
		cancelledTrades.forEach(trade => {
			const tradeCounters = counterTrades.filter(
				counterTrade =>
					counterTrade.tradeId === trade.tradeId &&
					!counterTrade.tradeInfo.assetsWithdrawn
			)

			if (tradeCounters.length) {
				toast.warn(
					`Review ${trade.state} listing by ${getShortText(trade.owner, 6)}`,
					{
						toastId: trade.tradeId,
						position: 'top-right',
						autoClose: 4000,
						onClick: () => history.push(`/explore/details/${trade.tradeId}`),
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						progress: undefined,
						pauseOnFocusLoss: false,
					}
				)
			}
		})

		// My funds are left or trade has been accepted
		const acceptedTrades = trades.filter(
			trade =>
				trade.state === TradeState.Accepted.toLowerCase() && !trade.assetsWithdrawn
		)

		acceptedTrades.forEach(trade => {
			const tradeCounters = counterTrades.filter(
				counterTrade =>
					counterTrade.tradeId === trade.tradeId &&
					!counterTrade.tradeInfo.assetsWithdrawn &&
					[TradeState.Accepted.toLowerCase()].includes(
						counterTrade?.tradeInfo?.state
					)
			)

			if (tradeCounters.length) {
				toast.warn(
					`Review ${trade.state} listing by ${getShortText(trade.owner, 6)}`,
					{
						toastId: trade.tradeId,
						position: 'top-right',
						autoClose: 4000,
						onClick: () => history.push(`/explore/details/${trade.tradeId}`),
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						progress: undefined,
						pauseOnFocusLoss: false,
					}
				)
			}
		})

		// My funds are left in accepted trade counter trades
		trades
			.filter(trade => trade.state === TradeState.Accepted.toLowerCase())
			.forEach(trade => {
				const tradeCounters = counterTrades.filter(
					counterTrade =>
						counterTrade.tradeId === trade.tradeId &&
						!counterTrade?.tradeInfo?.assetsWithdrawn &&
						[TradeState.Cancelled.toLowerCase()].includes(
							counterTrade?.tradeInfo?.state
						)
				)

				if (tradeCounters.length)
					toast.warn(
						`Review ${trade.state} listing by ${getShortText(trade.owner, 6)}`,
						{
							toastId: trade.tradeId,
							position: 'top-right',
							autoClose: 4000,
							onClick: () => history.push(`/explore/details/${trade.tradeId}`),
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: false,
							progress: undefined,
							pauseOnFocusLoss: false,
						}
					)
			})

		// My listings that are refused by offerer.
		trades.forEach(trade => {
			const tradeCounters = counterTrades.filter(
				counterTrade =>
					counterTrade.tradeId === trade.tradeId &&
					!counterTrade?.tradeInfo?.assetsWithdrawn &&
					[
						TradeState.Refused.toLowerCase(),
						TradeState.Created.toLowerCase(),
					].includes(counterTrade?.tradeInfo?.state)
			)

			if (tradeCounters.length)
				toast.warn(
					`Review ${trade.state} listing by ${getShortText(trade.owner, 6)}`,
					{
						toastId: trade.tradeId,
						position: 'top-right',
						autoClose: 4000,
						onClick: () => history.push(`/explore/details/${trade.tradeId}`),
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						progress: undefined,
						pauseOnFocusLoss: false,
					}
				)
		})
	}

	React.useEffect(() => {
		if (wallet.status === WalletStatus.WALLET_CONNECTED) {
			notifyCounters()
		}
	}, [wallet.network, myAddress, trades, counterTrades])

	return []
}
