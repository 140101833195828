import styled from 'styled-components'

export const StyledBadge = styled.div`
	display: flex;
	border-radius: 0px;
	color: #fff;
	background-color: ${props => props.color};
	font-size: 14px;
	padding: 0px;
	margin: 0px 0px;
	-webkit-box-align: center;
	align-items: center;
	justify-content: center;
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
`
