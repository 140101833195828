import React from 'react'
import CurrencyInput from 'components/CurrencyInput/CurrencyInput'
import { useFormikContext } from 'formik'
import { TradingForm } from 'pages/trading/Trading'

import TradeCard, { NFT } from 'pages/trading/components/TradeCard/TradeCard'
import { useWallet } from '@terra-money/use-wallet'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { Collection } from 'hooks/useMyAssets'
import {
	CurrenciesContainer,
	GridContainer,
	GridItem,
	CardContainer,
	TradingCardContainer,
	Subtitle,
	ContentSection,
	StyledTextArea,
} from './CounterOfferCard.styled'

export interface SelectedFunds {
	amountUST: string
	amountLuna: string
}

export interface CounterOfferCardProps {
	funds: {
		balanceUST: number
		balanceLuna: number
	}
	verifiedCollections: Collection[]
}

function CounterOfferCard({
	funds,
	verifiedCollections,
}: CounterOfferCardProps) {
	const { values, setFieldValue } = useFormikContext<TradingForm>()

	useFormikContext<TradingForm>()
	const wallet = useWallet()

	const removeNFTFromTrade = (nftToRemove: NFT) => {
		setFieldValue(
			'tradingNFTs',
			values.tradingNFTs.filter(
				(n: NFT) =>
					!(
						n.contractAddress === nftToRemove.contractAddress &&
						n.tokenId === nftToRemove.tokenId
					)
			)
		)
	}

	const myAddress = wallet.wallets[0]?.terraAddress

	return (
		<TradingCardContainer>
			<CardContainer>
				<GridContainer>
					{values.tradingNFTs.map((nft: NFT) => (
						<GridItem>
							<TradeCard
								verified={verifiedCollections.some(
									collection => collection.collectionAddress === nft.contractAddress
								)}
								key={JSON.stringify(nft)}
								onRemove={removeNFTFromTrade}
								closable
								nft={nft}
							/>
						</GridItem>
					))}
				</GridContainer>
			</CardContainer>
			<ContentSection>
				<Subtitle>You offer as: </Subtitle>
				<Subtitle>{myAddress}</Subtitle>
				<VerticalGap gap={40} />
				<Subtitle>Your Comments</Subtitle>
				<VerticalGap gap={8} />
				<StyledTextArea
					value={values.lookingForComments}
					onChange={e => setFieldValue('lookingForComments', e.target.value)}
					minRows={4}
					maxRows={4}
					maxLength={100}
				/>
				<VerticalGap gap={12} />
				<CurrenciesContainer>
					<CurrencyInput
						balance={funds.balanceLuna}
						value={values.tradingAmountLuna}
						onChange={e => setFieldValue('tradingAmountLuna', e.target.value)}
						currency='Luna'
					/>
					<CurrencyInput
						balance={funds.balanceUST}
						value={values.tradingAmountUST}
						onChange={e => setFieldValue('tradingAmountUST', e.target.value)}
						currency='UST'
					/>
				</CurrenciesContainer>
			</ContentSection>
		</TradingCardContainer>
	)
}

export default CounterOfferCard
