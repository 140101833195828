import { VerticalGap } from 'components/Layout/Layout.styled'
import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Checkbox } from 'theme/icons/checkbox.svg'
import { ReactComponent as PeersIcon } from 'theme/icons/users.svg'
// import { ReactComponent as TwoItems } from 'theme/icons/animation.svg'
// import { ReactComponent as MoreItems } from 'theme/icons/animation-more.svg'
import { NFT } from 'pages/trading/components/TradeCard/TradeCard'
import OverflowTip from 'components/OverflowTooltip/OverflowTooltip'
import {
	ActionsContainer,
	Box,
	DescriptionSection,
	DescriptionText,
	DividerLine,
	Grid,
	IconSection,
	Image,
	LeftActionIconContainer,
	LeftActions,
	ListingCardContainer,
	LookingForSection,
	LookingForText,
	PreviewImageContainer,
	PreviewImageOverlay,
	PreviewImageOverlayContainer,
	RightActions,
	SubPreviewImageContainer,
	TitleSection,
	TradeCardPreviewGrid,
} from './ListingCard.styled'

interface Fund {
	amount: number
	denom: string
	userfacingAmount: any
	userfacingDenom: string
}

export interface NFTCardProps {
	verified: boolean
	nfts: NFT[]
	funds: Fund[]
	isP2PListing?: boolean
	isMine: boolean
	lookingForTags?: string[]
	href?: string
	disabled?: boolean
	state: string
}

function ListingCard({
	verified,
	nfts,
	funds,
	href,
	disabled,
	lookingForTags,
	isMine,
	isP2PListing,
	state,
}: NFTCardProps) {
	const [previewNft, subPreviewNFT] = nfts || []

	const fundsFormatted = `${funds
		.map(
			({ userfacingAmount, userfacingDenom }) =>
				`${userfacingAmount} ${userfacingDenom}`
		)
		.join(' + ')}`

	return (
		<Link to={location => ({ ...location, pathname: href })}>
			<ListingCardContainer state={state} isMine={isMine} disabled={disabled}>
				<ActionsContainer>
					<LeftActions>
						<LeftActionIconContainer>
							{/* {nfts && nfts.length === 2 && <TwoItems />}
							{nfts && nfts.length === 3 && <MoreItems />}
							{nfts && nfts.length >= 4 && <MoreItems />} */}
						</LeftActionIconContainer>
					</LeftActions>
					<RightActions>{isP2PListing && <PeersIcon fill='#fff' />}</RightActions>
				</ActionsContainer>
				{nfts && nfts.length === 1 && (
					<PreviewImageContainer>
						<Image
							src={
								previewNft.imageUrl || 'https://via.placeholder.com/500/000000/000000/'
							}
							alt={previewNft.name}
						/>
					</PreviewImageContainer>
				)}

				{nfts && nfts.length === 2 && (
					<PreviewImageContainer>
						<PreviewImageOverlay>
							<PreviewImageOverlayContainer>
								<SubPreviewImageContainer>
									<Image
										src={
											subPreviewNFT?.imageUrl ||
											'https://via.placeholder.com/500/000000/000000/'
										}
										alt={subPreviewNFT.name}
									/>
								</SubPreviewImageContainer>
							</PreviewImageOverlayContainer>
						</PreviewImageOverlay>
						<Image
							src={
								previewNft?.imageUrl || 'https://via.placeholder.com/500/000000/000000/'
							}
							alt={previewNft.name}
						/>
					</PreviewImageContainer>
				)}

				{nfts && nfts.length > 2 && (
					<TradeCardPreviewGrid>
						{nfts.slice(0, 4).map(({ imageUrl, name, contractAddress, tokenId }) => (
							<PreviewImageContainer small key={`${contractAddress}_${tokenId}`}>
								<Image
									src={imageUrl || 'https://via.placeholder.com/250/000000/000000/'}
									alt={name}
								/>
							</PreviewImageContainer>
						))}
					</TradeCardPreviewGrid>
				)}
				{Boolean(!nfts.length) && (
					<PreviewImageContainer>
						<Image
							src='https://via.placeholder.com/250/000000/000000/'
							alt={previewNft?.name}
						/>
					</PreviewImageContainer>
				)}
				<VerticalGap gap={12} />

				<DescriptionSection>
					<IconSection />
					<OverflowTip>
						<TitleSection>
							{nfts && nfts.length === 1 && (
								<div>
									<DescriptionText>{previewNft.collectionName}</DescriptionText>

									<DescriptionText>
										{nfts.map(({ name }) => name).join(' , ')}
									</DescriptionText>
								</div>
							)}
							{nfts && nfts.length > 1 && (
								<OverflowTip>
									<div>
										<DescriptionText>{previewNft.collectionName}</DescriptionText>

										<DescriptionText>{`BUNDLE - ${nfts.length} NFTs`}</DescriptionText>
									</div>
								</OverflowTip>
							)}
						</TitleSection>
					</OverflowTip>
					<IconSection>
						{verified && (
							<div>
								<Checkbox />
							</div>
						)}
					</IconSection>
				</DescriptionSection>
				<LookingForSection>
					<DividerLine />

					<Grid>
						<LookingForText isMine={isMine}>looking for: </LookingForText>
						{Boolean(lookingForTags?.length) &&
							lookingForTags?.map(tag => (
								<Box key={tag} isMine={isMine}>
									<OverflowTip>
										<div>{tag}</div>
									</OverflowTip>
								</Box>
							))}
						{Boolean(!lookingForTags?.length) && (
							<Box isMine={isMine}>
								<OverflowTip>
									<div>Not specified</div>
								</OverflowTip>
							</Box>
						)}
					</Grid>

					<Grid>
						<LookingForText isMine={isMine}>funds: </LookingForText>
						<Box isMine={isMine}>
							<OverflowTip>
								<div>{fundsFormatted || '-'}</div>
							</OverflowTip>
						</Box>
					</Grid>
				</LookingForSection>
			</ListingCardContainer>
		</Link>
	)
}

ListingCard.defaultProps = {
	href: undefined,
	disabled: false,
	lookingForTags: [],
	isP2PListing: false,
}

export default ListingCard
