import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { AirdropForm, AidropRow } from 'pages/send/Send'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { NFTWithRecipient } from 'utils/blockchain/real/contracts/cw721'
import { isValidTerraAddress } from 'utils/blockchain/isValidAddress'

import { readCSV, trim } from 'utils/js/csvParser'

import { HtmlTooltip } from 'components/Tooltip/Tooltip'

import {
	Container,
	OptionsCard,
	TradingCardContainer,
	TradingCardFull,
	StyledButton,
	StyledInputButton,
	SendButtonContainer,
	MainContainer,
	TextAligner,
	ErrorAligner,
	ColoredCheckIcon,
	ColoredCloseIcon,
	TooltipIcon,
	IlliquidUl,
	Var,
} from './AirDropSelector.styled'

function NFTSender() {
	const { values, setFieldValue, submitForm, isSubmitting } =
		useFormikContext<AirdropForm>()
	const [csvFileName, setCsvFileName] = useState('')
	const [isInputValid, setIsInputValid] = useState(false)
	const [csvFileNameError, setCsvFileNameError] = useState('')

	function resetForm() {
		setCsvFileName('')
		setIsInputValid(false)
	}
	async function loadCsvFileToForm(e: React.ChangeEvent<HTMLInputElement>) {
		e.preventDefault()
		const file = e?.target?.files?.[0]
		resetForm()
		if (!file) {
			setCsvFileNameError('No file selected')
			return
		}
		if (file.type !== 'text/csv') {
			setCsvFileNameError('Wrong filetype, only csv files are accepted')
			return
		}
		const csv = await readCSV(file)
		// We keep only the pertinent rows and validate the input
		const formValues: AidropRow[] = csv.data
			.map((row: NFTWithRecipient) => ({
				nft: {
					nftAddress: trim(row.nftAddress),
					tokenId: trim(row.tokenId),
					recipient: trim(row.recipient),
				},
				status:
					isValidTerraAddress(trim(row.nftAddress)) &&
					isValidTerraAddress(trim(row.recipient)) &&
					row.tokenId,
			}))
			.filter((row: AidropRow) => row.nft.nftAddress)

		setIsInputValid(formValues.every(row => row.status))
		setCsvFileNameError('')
		setFieldValue('csv', [])
		setFieldValue('csv', formValues)
		setCsvFileName(file.name)
	}

	return (
		<MainContainer>
			<TradingCardContainer>
				<OptionsCard>
					<Container>
						<StyledInputButton variant='contained' component='label'>
							Upload csv File
							<input type='file' hidden onChange={e => loadCsvFileToForm(e)} />
						</StyledInputButton>
						<HtmlTooltip
							title={
								<>
									Upload a csv file with the following fields :
									<IlliquidUl>
										<Var>
											<code>nft_address</code>
										</Var>
										<Var>
											<code>token_id</code>
										</Var>
										<Var>
											<code>recipient</code>
										</Var>
									</IlliquidUl>
								</>
							}
							placement='top'
						>
							<TooltipIcon />
						</HtmlTooltip>
					</Container>
					{csvFileName && (
						<TextAligner>{`File Selected : ${csvFileName}`}</TextAligner>
					)}
					{csvFileNameError && <ErrorAligner>{csvFileNameError}</ErrorAligner>}
				</OptionsCard>
				<SendButtonContainer>
					<StyledButton
						onClick={submitForm}
						disabled={isSubmitting || !isInputValid}
					>
						Airdrop
					</StyledButton>
				</SendButtonContainer>
			</TradingCardContainer>
			<TradingCardContainer>
				<TradingCardFull>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label='simple table'>
							<TableHead>
								<TableRow>
									<TableCell>NFT Address</TableCell>
									<TableCell align='right'>Token id</TableCell>
									<TableCell align='right'>Recipient Address</TableCell>
									<TableCell align='right'>Line Status</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{values.csv.map(({ nft, status }) => (
									<TableRow
										key={`${nft.nftAddress}${nft.tokenId}`}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component='th' scope='row'>
											{nft.nftAddress}
										</TableCell>
										<TableCell align='right'>{nft.tokenId}</TableCell>
										<TableCell align='right'>{nft.recipient}</TableCell>
										<TableCell align='right'>
											{status ? <ColoredCheckIcon /> : <ColoredCloseIcon />}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</TradingCardFull>
			</TradingCardContainer>
		</MainContainer>
	)
}

export default NFTSender
