import React from 'react'
import { useFormikContext } from 'formik'
import { useRecoilState } from 'recoil'
import { uniqBy } from 'lodash'
import { SendingForm } from 'pages/send/Send'
import { filterOptions } from 'App'

import { isValidTerraAddress } from 'utils/blockchain/isValidAddress'
import {
	OptionsCard,
	Container,
	WalletAddressInput,
	StyledButton,
	SendActionsContainer,
} from './SendNFTTo.styled'
import { NFT } from '../../../trading/components/TradeCard/TradeCard'

interface SendNFTToProps {
	nfts: NFT[]
}

function NFTSender({ nfts }: SendNFTToProps) {
	const { values, setFieldValue, submitForm, isSubmitting, isValid } =
		useFormikContext<SendingForm>()

	const [filterOptionsValue] = useRecoilState(filterOptions)

	const addAllNfts = () => {
		const newSelectedNFTs = uniqBy(
			[
				...values.tradingNFTs,
				...nfts.filter(nft =>
					filterOptionsValue
						.filter(x => x.enabled)
						.map(x => x.value)
						.includes(nft.contractAddress)
				),
			],
			x => x
		)

		setFieldValue('tradingNFTs', newSelectedNFTs)
	}

	const removeAllNfts = () => {
		setFieldValue('tradingNFTs', [])
	}

	return (
		<Container>
			<OptionsCard>
				Recipient
				<WalletAddressInput
					value={values.recipient}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setFieldValue('recipient', e.target.value)
					}}
					placeholder='wallet address'
					fullWidth
				/>
			</OptionsCard>
			<SendActionsContainer>
				<StyledButton onClick={addAllNfts}>Add all</StyledButton>

				<StyledButton onClick={removeAllNfts}>Remove all</StyledButton>

				<StyledButton
					onClick={submitForm}
					disabled={
						!isValidTerraAddress(values.recipient) || isSubmitting || !isValid
					}
				>
					Send NFTs
				</StyledButton>
			</SendActionsContainer>
		</Container>
	)
}

export default NFTSender
