import styled from 'styled-components'
import { Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export const Container = styled.div`
	display: flex;
	align-items: center;
`

export const TooltipIcon = styled(HelpOutlineIcon)`
	margin-left: 6px;
	height: 0.8em !important;
	cursor: pointer;
`

export const TradingCard = styled.div`
	flex-direction: row;
	background: #18283d;
	display: flex;
	padding: 16px;
`

export const TradingCardFull = styled(TradingCard)`
	width: 100%;
`

export const OptionsCard = styled(TradingCard)`
	flex-direction: row;
	align-items: center;
	gap: 24px;
	width: 50%;
`

export const SendButtonContainer = styled.div`
	width: 25%;
	display: flex;
	flex-direction: row;
	justify-content: end;
`

export const TradingCardContainer = styled.div`
	display: flex;
	flex-direction: row;
	grid-column-gap: 12px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`

export const ErrorSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 28px;
`

export const ErrorMessage = styled.div``

export const StyledButton = styled(Button)<{ disabled?: boolean }>`
	&& {
		padding: 0 12px;
		font-family: 'Pixelade';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		background: linear-gradient(
			89.97deg,
			#88a1c1 -77.6%,
			#3f608c 56.9%,
			#2e5180 88.51%,
			#0b59c4 114.84%
		);
		flex-shrink: 0;
		opacity: ${props => (props.disabled ? 0.5 : 1)};
		box-sizing: border-box;
		color: ${props => (props.disabled ? '#A0BFE4' : '#A0BFE4')};

		&:hover {
			background: linear-gradient(
				89.97deg,
				#88a1c1 -77.6%,
				#3f608c 56.9%,
				#2e5180 88.51%,
				#0b59c4 114.84%
			);
			opacity: ${props => (props.disabled ? 0.5 : 1)};
			color: ${props => (props.disabled ? '#A0BFE4' : '#A0BFE4')};
		}
	}
`

export const StyledInputButton = styled(Button).attrs({
	component: 'label',
})`
	&& {
		padding: 0 12px;
		font-family: 'Pixelade';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		background: linear-gradient(
			89.97deg,
			#88a1c1 -77.6%,
			#3f608c 56.9%,
			#2e5180 88.51%,
			#0b59c4 114.84%
		);
		flex-shrink: 0;
		opacity: 1;
		box-sizing: border-box;
		color: #a0bfe4;

		&:hover {
			background: linear-gradient(
				89.97deg,
				#88a1c1 -77.6%,
				#3f608c 56.9%,
				#2e5180 88.51%,
				#0b59c4 114.84%
			);
			opacity: 1;
			color: #a0bfe4;
		}
	}
`

export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	gap: 24px;
`

export const TextAligner = styled.div`
	font-family: 'Pixelade';
	font-size: 25px;
`
export const ErrorAligner = styled.div`
	font-family: 'Pixelade';
	font-size: 25px;
	color: red;
`

export const ColoredCheckIcon = styled(CheckIcon)`
	color: green;
`

export const ColoredCloseIcon = styled(CloseIcon)`
	color: red;
`

export const IlliquidUl = styled.ul`
	list-style: none;
`

export const Var = styled.li`
	&& {
		font-weight: bold;
		&:before {
			content: '';
			display: inline-block;
			height: 12px;
			width: 12px;
			background-image: url('/favicon.ico');
			background-size: contain;

			background-repeat: no-repeat;
			margin-right: 4px;
		}
	}
`
