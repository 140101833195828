import React from 'react'
import { StyledBadge } from './Badge.styled'

interface BadgeProps {
	color: string
}

export default function Badge({
	children,
	color,
}: { children: React.ReactNode } & BadgeProps) {
	return (
		<StyledBadge color={color}>
			<div>{children}</div>
		</StyledBadge>
	)
}
