import React from 'react'

import Container from 'components/Container/Container'
import { useRecoilState } from 'recoil'
import { Formik, FormikProps } from 'formik'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'

import cw721Contract, {
	NFTWithRecipient,
} from 'utils/blockchain/real/contracts/cw721'
import { TxReceipt } from 'utils/blockchain/blockchain.interface'
import useBroadcastingTx from 'hooks/useBroadcastingTx'
import { asyncAction } from 'utils/js/asyncAction'
import { appLoadingState } from 'App'
import * as yup from 'yup'
import { StyledLoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay'

import useTransactionError from 'hooks/useTransactionError'
import useVerifiedNFTs from 'hooks/useVerifiedCollections'
import { noop } from 'lodash'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { TabTitle, TabsTitle, ToastLink } from './Send.styled'
import YourNFTs from '../trading/components/YourNFTs/YourNFTs'
import useMyAssets from '../../hooks/useMyAssets'
import { NFT } from '../trading/components/TradeCard/TradeCard'
import WantToSend from './components/WantToSend/WantToSend'
import SendNFTTo from './components/SendNFTTo/SendNFTTo'
import AirDropSelector from './components/AirDropSelector/AirDropSelector'

import { MainContainer, TradingContainer } from '../trading/Trading.styled'

export interface SendingForm {
	tradingNFTs: NFT[]
	recipient: string
}

enum TABS {
	Multisender,
	Airdropper,
}

export interface AidropRow {
	nft: NFTWithRecipient
	status: boolean
}

export interface AirdropForm {
	csv: AidropRow[]
}

const createTradeValidationSchema = yup.object().shape({
	tradingNFTs: yup
		.array()
		.min(1, 'Please select at least 1 NFT')
		.required('Please select at least 1 NFT'),
})

interface TabPanelProps {
	children: React.ReactNode
	index: number
	value: number
	top: string
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, top, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={{ marginTop: `${top}px` }}
			{...other}
		>
			<Box style={{ padding: 0, paddingTop: 24 }} sx={{ p: 3 }}>
				{children}
			</Box>
		</div>
	)
}

const renderToastContent = ({ url }: { url?: string }) => (
	<div>
		<div>NFTs were sent successfully.</div>
		<VerticalGap gap={8} />
		<ToastLink href={url} target='_blank' rel='noreferrer'>
			Open in Terra Finder
		</ToastLink>
	</div>
)

export default function Trading() {
	const [verifiedCollections] = useVerifiedNFTs()
	const [showTransactionError] = useTransactionError()
	const {
		nfts,
		collections,
		nftsPartiallyLoading: nftsLoading,
		fetchMyAssets,
	} = useMyAssets()
	const [appLoading, setAppLoading] = useRecoilState(appLoadingState)

	const multisenderFormRef = React.useRef<FormikProps<SendingForm>>(null)

	const [txReceipt, setTxReceipt] = React.useState<TxReceipt | null>(null)

	const onSuccessBroadcast = async () => {
		toast.success(renderToastContent({ url: txReceipt?.txTerraFinderUrl }), {
			position: 'top-right',
			autoClose: false,
			onClick: noop,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			pauseOnFocusLoss: false,
		})

		multisenderFormRef?.current?.resetForm()

		await fetchMyAssets()
		setAppLoading(false)
	}

	const { setLoading, loading } = useBroadcastingTx(
		txReceipt?.txId,
		onSuccessBroadcast
	)

	const onSubmit = async (promise: Promise<any>) => {
		setAppLoading(true)
		setLoading({ ...loading, send: true })
		const [error, txResponse] = await asyncAction(promise)

		if (txResponse) {
			setTxReceipt(txResponse)
		}
		if (error) {
			showTransactionError(error)
			setAppLoading(false)
		}
		setLoading({ ...loading, send: false })
	}

	const [value, setValue] = React.useState(0)

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	return (
		<StyledLoadingOverlay
			classNamePrefix='Primary_'
			flex
			active={appLoading}
			spinner
		>
			<MainContainer>
				<Container>
					<TabsTitle
						value={value}
						onChange={handleChange}
						centered
						TabIndicatorProps={{
							style: {
								height: '2px',
								backgroundColor: 'rgba(255, 255, 255, 0.5)',
							},
						}}
					>
						<TabTitle label='Multisender' />
						<TabTitle label='Airdropper' />
					</TabsTitle>
					<TabPanel value={value} index={TABS.Multisender} top='-24'>
						<Formik
							validateOnMount // Validation is not triggered on mount, we use this prop.
							onSubmit={async ({ recipient, tradingNFTs }: SendingForm) =>
								onSubmit(cw721Contract.singleMultisender(recipient, tradingNFTs))
							}
							validationSchema={createTradeValidationSchema}
							initialValues={{
								tradingNFTs: [],
								recipient: '',
							}}
							innerRef={multisenderFormRef}
						>
							<>
								<TradingContainer>
									<WantToSend verifiedCollections={verifiedCollections} />
								</TradingContainer>
								<TradingContainer>
									<SendNFTTo nfts={nfts} />
								</TradingContainer>
								<YourNFTs
									verifiedCollections={verifiedCollections}
									isLoading={nftsLoading}
									nfts={nfts}
									collections={collections}
								/>
							</>
						</Formik>
					</TabPanel>
					<TabPanel value={value} index={TABS.Airdropper} top='0'>
						<Formik
							validateOnMount // Validation is not triggered on mount, we use this prop.
							onSubmit={async ({ csv }: AirdropForm) =>
								onSubmit(cw721Contract.transferMultipleToken(csv.map(row => row.nft)))
							}
							initialValues={{
								csv: [],
							}}
						>
							<TradingContainer>
								<AirDropSelector />
							</TradingContainer>
						</Formik>
					</TabPanel>
				</Container>
			</MainContainer>
		</StyledLoadingOverlay>
	)
}
