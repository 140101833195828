import styled from 'styled-components'

export const ActionsContainer = styled.div`
	position: absolute;
	display: flex;
	z-index: 99;
	width: 100%;
	height: 240px;
	padding: 10px;
`

export const LeftActions = styled.div`
	display: flex;
`

export const LeftActionIconContainer = styled.div`
	margin-left: -8px;
	margin-top: -8px;
`

export const RightActions = styled.div`
	margin-left: auto;
	display: flex;
`

export const ListingCardContainer = styled.div<{
	disabled?: boolean
	isMine: boolean
	state: string
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	background: ${props =>
		props.isMine
			? 'linear-gradient(138.44deg, #59779F 13.92%, #1A4989 94.55%)'
			: '#18283d'};
	width: 100%;
	height: 425px;
	overflow: hidden;

	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
	transition: all 0.5s ease-in-out;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
		transform: translate3d(0px, -2px, 0px);
	}

	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

export const PreviewImageOverlay = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	position: absolute;
	flex-direction: column;
	z-index: 99;
`

export const PreviewImageOverlayContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
`

export const SubPreviewImageContainer = styled.div`
	max-width: 85px;
	max-height: 85px;
	overflow: hidden;
	border: 3px solid #18283d;
`

export const PreviewImageContainer = styled.div<{ small?: boolean }>`
	position: relative;
	max-width: 240px;
	max-height: 240px;
	overflow: hidden;

	${props =>
		props.small
			? `
		max-width: 115px;
		max-height: 115px;
	`
			: ``}
`

export const Image = styled.img`
	max-width: 100%;
	max-height: 100%;
`

export const DividerLine = styled.div`
	margin: 7px 0px;
	border: 1px solid #89a8cf;
`

export const IconSection = styled.div`
	flex: 1;
	max-width: 50px;
	padding: 4px 4px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
`

export const TitleSection = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	overflow: hidden;
`

export const DescriptionSection = styled.div`
	display: flex;
`

export const DescriptionText = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	text-align: center;
	color: #e5ecf5;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const TradeCardPreviewGrid = styled.div`
	display: grid;

	height: 240px;
	overflow: hidden;

	${props => props.theme.breakpoints.down('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	}

	${props => props.theme.breakpoints.up('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
	}

	grid-column-gap: 8px;
	grid-row-gap: 8px;
`

export const LookingForSection = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 13px;
`

export const LookingForText = styled.div<{ isMine: boolean }>`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;

	color: ${props => (props.isMine ? '#fff' : '#89a8cf')};
`

export const Grid = styled.div`
	margin-top: 8px;

	display: grid;

	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

	grid-column-gap: 4px;

	grid-row-gap: 4px;
`

export const Box = styled.div<{ isMine: boolean }>`
	margin: 0;
	display: flex;
	flex-direction: row;
	border: 1px solid ${props => (props.isMine ? '#fff' : '#89a8cf')};
	box-sizing: border-box;
	padding: 0px 12px;
	justify-content: center;
	align-items: center;
	height: 24px;
	color: ${props => (props.isMine ? '#fff' : '#89a8cf')};
	text-align: center;
	font-size: 10px;
`
