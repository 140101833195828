import React from 'react'

import Container from 'components/Container/Container'
import { ReactComponent as Sync } from 'theme/icons/sync.svg'
import { useRecoilState } from 'recoil'
import { Formik } from 'formik'

import { listTradeOffers } from 'utils/blockchain/real/contracts/p2pTrading'
import { TxReceipt } from 'utils/blockchain/blockchain.interface'
import useBroadcastingTx from 'hooks/useBroadcastingTx'
import { asyncAction } from 'utils/js/asyncAction'
import { appLoadingState } from 'App'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import useTransactionError from 'hooks/useTransactionError'
import { StyledLoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay'
import useVerifiedNFTs from 'hooks/useVerifiedCollections'
import WantToTrade from './components/WantToTrade/WantToTrade'
import {
	MainContainer,
	MiddleSectionContainer,
	Title,
	TradingContainer,
} from './Trading.styled'
import LookingFor from './components/LookingFor/LookingFor'
import YourNFTs from './components/YourNFTs/YourNFTs'
import useMyAssets from '../../hooks/useMyAssets'
import { NFT } from './components/TradeCard/TradeCard'

export interface TradingForm {
	tradingNFTs: NFT[]
	tradingAmountUST: string
	tradingAmountLuna: string
	lookingForAssets: string[]
	lookingForWhitelistedAddress: string
	lookingForComments: string
}

const createTradeValidationSchema = yup.object().shape({
	tradingNFTs: yup
		.array()
		.min(1, 'Please select at least 1 NFT')
		.required('Please select at least 1 NFT'),
})

export default function Trading() {
	const [verifiedCollections] = useVerifiedNFTs()
	const [showTransactionError] = useTransactionError()
	const history = useHistory()
	const {
		funds,
		nfts,
		collections,
		nftsPartiallyLoading: nftsLoading,
	} = useMyAssets()
	const [appLoading, setAppLoading] = useRecoilState(appLoadingState)

	const [txReceipt, setTxReceipt] = React.useState<TxReceipt | null>(null)

	const onSuccessBroadcast = async ({ tradeId }: { tradeId: string }) => {
		history.push(`/explore/details/${tradeId}`)
	}

	const { setLoading, loading } = useBroadcastingTx(
		txReceipt?.txId,
		onSuccessBroadcast
	)

	const onSubmit = async ({
		tradingNFTs,
		tradingAmountUST,
		tradingAmountLuna,
		lookingForAssets,
		lookingForWhitelistedAddress,
		lookingForComments,
	}: TradingForm) => {
		setAppLoading(true)
		setLoading({ ...loading, send: true })

		const [error, txResponse] = await asyncAction(
			listTradeOffers([
				{
					whitelistedAddress: lookingForWhitelistedAddress,
					amountUST: tradingAmountUST.replace(',', '.'),
					amountLuna: tradingAmountLuna.replace(',', '.'),
					comment: lookingForComments,
					cw721Tokens: tradingNFTs,
					nftsWanted: lookingForAssets,
				},
			])
		)
		if (txResponse) {
			setTxReceipt(txResponse)
		}
		if (error) {
			showTransactionError(error)
			setAppLoading(false)
		}
		setLoading({ ...loading, send: false })
	}

	return (
		<StyledLoadingOverlay
			classNamePrefix='Primary_'
			flex
			active={appLoading}
			spinner
		>
			<MainContainer>
				<Formik
					validateOnMount // Validation is not triggered on mount, we use this prop.
					onSubmit={onSubmit}
					validationSchema={createTradeValidationSchema}
					initialValues={{
						tradingNFTs: [],
						tradingAmountUST: '',
						tradingAmountLuna: '',
						lookingForAssets: [],
						lookingForWhitelistedAddress: '',
						lookingForComments: '',
					}}
				>
					<Container>
						<Title>create a trade</Title>

						<TradingContainer>
							<WantToTrade verifiedCollections={verifiedCollections} funds={funds} />
							<MiddleSectionContainer>
								<Sync width={48} height={48} fill='#89A8CF' />
							</MiddleSectionContainer>
							<LookingFor verifiedCollections={verifiedCollections} />
						</TradingContainer>
						<YourNFTs
							isLoading={nftsLoading}
							nfts={nfts}
							collections={collections}
							verifiedCollections={verifiedCollections}
						/>
					</Container>
				</Formik>
			</MainContainer>
		</StyledLoadingOverlay>
	)
}
