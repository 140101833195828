import { TextareaAutosize } from '@mui/material'
import styled from 'styled-components'

export const Title = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: #e5ecf5;
`

export const Subtitle = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	color: #e5ecf5;
`

export const CardContainer = styled.div<{ isMine?: boolean }>`
	position: relative;
	display: flex;
	height: 291px;
	border: 2px solid #89a8cf;
	background: ${props =>
		props.isMine
			? 'linear-gradient(138.44deg, #59779F 13.92%, #1A4989 94.55%)'
			: '#18283d'};
`

export const StyledTextArea = styled(TextareaAutosize)<{ isTrader?: boolean }>`
	font-family: Biryani;
	background: ${props =>
		props.isTrader ? '#1A4989' : 'rgba(137, 168, 207, 0.2)'};
	border: 1px solid ${props => (props.isTrader ? '#3cbfe9' : '#89a8cf')};
	resize: none;

	font-size: 12px;
	padding: 8px;

	::placeholder {
		font-family: Biryani;
		opacity: 0.75;
		color: #89a8cf;
		font-size: 12px;
	}

	&:hover {
		border-radius: 0px;
	}

	color: #89a8cf;
`

export const ContentSection = styled.div`
	flex: 1;
	display: flex;

	padding: 10px 24px;

	overflow: hidden;
`

export const NFTDetailsVerifiedSection = styled.div`
	margin-left: auto;
	padding: 4px;
`

export const NFTDetailsSection = styled.div`
	position: relative;
	display: flex;

	flex-direction: column;

	padding-right: 24px;
	border-right: 1px solid #89a8cf;

	max-width: 384px;

	flex: 1;
`

export const NFTOfferDetails = styled.div`
	z-index: 99;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 0px 24px;

	padding-bottom: 8px;
`

export const OfferGridContainer = styled.div`
	flex: 1;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

	grid-row-gap: 4px;
	grid-column-gap: 4px;
`

export const FundBox = styled.div`
	margin: 0;
	margin-bottom: 4px;
	display: flex;
	flex-direction: row;
	border: 1px solid #89a8cf;
	box-sizing: border-box;
	padding: 0px 12px;
	justify-content: center;
	align-items: center;
	height: 24px;
	color: #89a8cf;
	text-align: center;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:last-child {
		margin-bottom: 0;
	}
`

export const TraitBoxGrid = styled.div<{ count: number }>`
	display: grid;

    
	grid-template-columns: ${props =>
		props.count > 4
			? 'repeat(auto-fill, minmax(140px, 1fr));'
			: 'repeat(auto-fill, 1fr);'}

	grid-row-gap: 4px;
	grid-column-gap: 4px;
`

export const TraitBox = styled.div<{ isMine?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${props => (props.isMine ? `#89a8cf` : `18283d`)};
	border: 1px solid
		${props => (props.isMine ? `#89a8cf` : `rgba(137, 168, 207, 0.5)`)};
	padding: 4px 6px;
	overflow: hidden;
`

export const TraitText = styled.div`
	font-family: 'Biryani';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	margin-top: 2px;
	color: #89a8cf;
`

export const ImageContainer = styled.div`
	max-width: 291px;
	max-height: 291px;
	position: relative;
	overflow-y: hidden;
`

export const ImageOverlay = styled.div`
	position: absolute;
	display: flex;
	justify-content: flex-end;
	z-index: 9;
	width: 100%;
	height: 100%;
	padding: 10px;
`

export const Image = styled.img`
	max-width: 100%;
	max-height: 100%;
`

export const OfferInfoTitle = styled.div<{ isMine?: boolean }>`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: 400;
	font-size: 25px;

	color: ${props => (props.isMine ? '#3cbfe9' : '#fff')};
`

export const OfferInfoSubtitle = styled.div<{ isMine?: boolean }>`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	background: ${props =>
		props.isMine
			? 'linear-gradient(90deg, #3cbfe9 1.77%, #5792dc 56.78%)'
			: '#fff'};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`

export const OfferInfoFooter = styled.div`
	display: flex;
`

export const OfferInfoLookingForGrid = styled.div`
	flex: 1;

	display: grid;

	margin-left: 24px;

	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

	grid-column-gap: 8px;

	grid-row-gap: 12px;
`

export const OfferInfoLookingForBox = styled.div`
	margin: 0;
	display: flex;
	flex-direction: row;
	border: 1px solid #89a8cf;
	box-sizing: border-box;
	padding: 0px 12px;
	justify-content: center;
	align-items: center;
	height: 24px;
	color: #89a8cf;
	text-align: center;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const Overlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const LeftCursorContainer = styled.div`
	width: 80px;
	z-index: 777;

	${props => props.theme.breakpoints.down('xl')} {
		margin-left: -20px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		margin-left: -80px;
	}
`

export const RightCursorContainer = styled.div`
	width: 80px;

	${props => props.theme.breakpoints.down('xl')} {
		margin-right: -10px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		margin-right: -65px;
	}

	z-index: 777;
`
