import { TextareaAutosize } from '@mui/material'
import styled from 'styled-components'

export const CurrencyContainer = styled.div`
	margin-top: 54px;
	display: flex;
`

export const Title = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 42px;
	color: #e5ecf5;

	${props => props.theme.breakpoints.down('xl')} {
		font-size: 28px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		font-size: 42px;
	}
`

export const CardContainer = styled.div`
	position: relative;
	display: flex;
	height: 300px;
	overflow: hidden;

	${props => props.theme.breakpoints.down('xl')} {
		width: 500px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		width: 552px;
	}
`
export const TradingCardContainer = styled.div`
	margin-top: 24px;
	padding: 12px;
	grid-column-gap: 12px;
	display: flex;
	flex: 1;
	background: linear-gradient(138.44deg, #59779f 13.92%, #1a4989 94.55%);
	border: 2px solid #89a8cf;
`

export const GridContainer = styled.div`
	flex: 1;
	width: 100%;
	display: grid;

	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 7px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #566e8e;
		border-radius: 0px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #23354d;
		border-radius: 0px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #8caad0;
	}

	grid-column-gap: 12px;
	grid-row-gap: 12px;
	${props => props.theme.breakpoints.down('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	}

	${props => props.theme.breakpoints.up('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(239px, 1fr));
	}
`

export const CurrenciesContainer = styled.div`
	margin-top: auto;
	width: 100%;
	display: grid;
	grid-column-gap: 12px;

	grid-template-columns: 1fr 1fr 1fr;
`

export const GridItem = styled.div`
	${props => props.theme.breakpoints.down('xl')} {
		width: 200px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		width: 239px;
	}

	width: 239px;
`

export const Subtitle = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	color: #e5ecf5;
`

export const ContentSection = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-right: 24px;
`

export const StyledTextArea = styled(TextareaAutosize)`
	font-family: Biryani;
	background: rgba(137, 168, 207, 0.2);
	border: 1px solid #89a8cf;
	resize: none;

	font-size: 12px;
	padding: 8px;

	::placeholder {
		opacity: 0.75;
		color: #89a8cf;
		font-size: 14px;
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	&:hover {
		border-radius: 0px;
	}

	color: #89a8cf;
`
