import styled from 'styled-components'

export const RestrictedView = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 777;
	padding: 0px 0px;

	background: linear-gradient(
		180deg,
		rgba(229, 236, 245, 0.094) 0%,
		rgba(229, 236, 245, 0) 107.14%,
		rgba(0, 0, 0, 0.1) 107.14%
	);
	backdrop-filter: blur(22px);
`

export default RestrictedView
