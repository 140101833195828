import { createTheme } from '@mui/material/styles'
import { palette } from './palette'

declare module '@mui/material/styles' {
	// eslint-disable-next-line no-unused-vars
	interface Theme {
		typography: {
			body4: {
				[x: string]:
					| string
					| number
					| {
							fontSize: number
							lineHeight: string
					  }
				fontFamily: string
				fontSize: number
				lineHeight: string
			}
			body3: {
				fontFamily: string
				fontSize: number
			}
			body2: {
				fontFamily: string
				fontSize: number
			}
			body1: {
				fontFamily: string
				fontSize: number
				lineHeight: string
			}
			h900: {
				fontSize: number
				fontWeight: number
				lineHeight: string
				[x: string]:
					| string
					| number
					| {
							fontSize: number
							fontWeight: number
							lineHeight: string
					  }
			}
			h800: {
				fontSize: number
				fontWeight: number
				lineHeight: string
				[x: string]:
					| string
					| number
					| {
							fontSize: number
							lineHeight: string
					  }
			}
			h700: {
				fontSize: number
				fontWeight: number
				lineHeight: string
				[x: string]:
					| string
					| number
					| {
							fontSize: number
							lineHeight: string
					  }
			}
			h600: {
				fontSize: number
				fontWeight: number
				lineHeight: string
				[x: string]:
					| string
					| number
					| {
							fontSize: number
							lineHeight: string
					  }
			}
			h500: {
				fontSize: number
				fontWeight: number
				lineHeight: string
				[x: string]:
					| string
					| number
					| {
							fontSize: number
							lineHeight: string
					  }
			}
			h400: {
				fontSize: number
				fontWeight: number
				lineHeight: string
				[x: string]:
					| string
					| number
					| {
							fontSize: number
							lineHeight: string
					  }
			}
			h300: {
				fontSize: number
				fontWeight: number
				lineHeight: string
			}
			h200: {
				fontSize: number
				fontWeight: number
				lineHeight: string
			}
			h100: {
				fontSize: number
				fontWeight: number
				lineHeight: string
			}
		}
	}

	// eslint-disable-next-line no-unused-vars
	interface PaletteOptions {
		light?: {
			light: string
			main: string
			dark: string
		}
		dark: {
			main: string
			light: string
		}
		tertiary: {
			light: string
			main: string
		}
	}
}

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			md: 992,
			lg: 1280,
			xl: 1580,
		},
	},
	palette: {
		mode: 'light',
		primary: {
			main: palette.alphaLight[50],
			light: palette.alphaLight[50],
			dark: palette.alphaLight[50],
		},
		error: {
			main: palette.red[300],
			dark: palette.red[400],
			light: palette.red[200],
		},
		warning: {
			main: palette.orange[300],
			dark: palette.orange[400],
			light: palette.orange[200],
		},
		success: {
			main: palette.green[300],
			dark: palette.green[400],
			light: palette.green[200],
		},
		info: {
			main: palette.blue[300],
			dark: palette.blue[400],
			light: palette.blue[200],
		},
		light: {
			light: '#e2e2e3',
			main: '#d5d6d8',
			dark: '#5f6061',
		},
		dark: {
			main: palette.secondaryGray[900],
			light: '#171616',
		},
		tertiary: {
			light: palette.dark[50],
			main: palette.dark[50],
		},
		grey: {
			...palette.gray,
			A100: palette.secondaryGray[100],
			A200: palette.secondaryGray[200],
		},
		text: {
			primary: palette.alphaLight[1000],
			secondary: palette.alphaLight[1000],
		},
		action: {
			disabled: '#A0BFE4',
		},
	},
	typography: {
		fontFamily: "'Biryani', sans-serif",
		fontSize: 16,
		button: {
			textTransform: 'none',
		},
	},
	components: {
		MuiMenuItem: {},
		MuiSelect: {
			styleOverrides: {
				icon: {
					padding: 0,
					margin: 0,
					top: '10%',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					border: 0,
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},

		MuiInputBase: {
			styleOverrides: {
				input: {
					'&::placeholder': {
						fontFamily: "'Biryani', sans-serif",
						opacity: 0.75,
						color: '#89A8CF',
						fontSize: '14px',
						textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
					},
					color: '#89A8CF',
					fontSize: '14px',
				},
			},
		},
		MuiInput: {
			defaultProps: {
				disableUnderline: false,
			},
			styleOverrides: {
				root: {
					fontSize: '18px',
				},

				underline: {
					'&:before': {
						borderBottom: '2px solid #89A8CF',
					},
					'&:hover:not($disabled):not($focused):not($error):before': {
						borderBottom: '2px solid #89A8CF',
					},
					'&:after': {
						borderBottom: '3px solid #89A8CF',
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: '#353b51',
					color: 'white',
					padding: 6,
					borderRadius: 4,
					boxShadow: 'none',
				},
				arrow: {
					color: '#353b51',
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				flexContainer: {
					gap: '48px',
				},
			},
		},
		MuiTableContainer: {
			styleOverrides: {
				root: {
					backgroundColor: 'unset',
					boxShadow: 'none',
				},
			},
		},
	},
})

theme = {
	...theme,
	typography: {
		...theme.typography,
		body4: {
			fontFamily: 'Biryani, sans-serif',
			fontSize: 16,
			lineHeight: '24px',
			[theme.breakpoints.up('md')]: {
				fontSize: 18,
				lineHeight: '24px',
			},
		},
		body3: {
			fontFamily: 'Biryani, sans-serif',
			fontSize: 16,
		},
		body2: {
			fontFamily: 'Biryani, sans-serif',
			fontSize: 14,
		},
		body1: {
			fontFamily: 'Biryani, sans-serif',
			fontSize: 12,
			lineHeight: '18px',
		},
		h900: {
			fontSize: 40,
			fontWeight: 700,
			lineHeight: '48px',
			[theme.breakpoints.up('md')]: {
				fontSize: 72,
				fontWeight: 700,
				lineHeight: '108px',
			},
		},
		h800: {
			fontSize: 32,
			fontWeight: 700,
			lineHeight: '36px',
			[theme.breakpoints.up('md')]: {
				fontSize: 56,
				lineHeight: '84px',
			},
		},
		h700: {
			fontSize: 32,
			fontWeight: 700,
			lineHeight: '40px',
			[theme.breakpoints.up('md')]: {
				fontSize: 48,
				lineHeight: '60px',
			},
		},
		h600: {
			fontSize: 19,
			fontWeight: 700,
			lineHeight: '24px',
			[theme.breakpoints.up('md')]: {
				fontSize: 32,
				lineHeight: '48px',
			},
		},
		h500: {
			fontSize: 16,
			fontWeight: 700,
			lineHeight: '18px',
			[theme.breakpoints.up('md')]: {
				fontSize: 24,
				lineHeight: '36px',
			},
		},
		h400: {
			fontSize: 16,
			fontWeight: 700,
			lineHeight: '20px',
			[theme.breakpoints.up('md')]: {
				fontSize: 20,
				lineHeight: '32px',
			},
		},
		h300: {
			fontSize: 16,
			fontWeight: 600,
			lineHeight: '24px',
		},
		h200: {
			fontSize: 14,
			fontWeight: 600,
			lineHeight: '20px',
		},
		h100: {
			fontSize: 12,
			fontWeight: 600,
			lineHeight: '16px',
		},
	},
}

export default theme
