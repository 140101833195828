import { Button } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
	margin-top: 40px;
	${props => props.theme.breakpoints.down('xl')} {
		margin-top: 24px;
	}

	flex: 1;
`

export const Card = styled.div`
	flex-direction: column;
	background: #18283d;
	display: flex;
	padding: 16px;
`

export const SectionTitle = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;

	color: #89a8cf;
`

export const GridContainer = styled.div`
	height: 300px;
	width: 100%;
	display: grid;

	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #23354d;
		border-radius: 0px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #566e8e;
		border-radius: 0px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #8caad0;
	}

	grid-column-gap: 12px;
	grid-row-gap: 12px;
	${props => props.theme.breakpoints.down('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	${props => props.theme.breakpoints.up('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(239px, 1fr));
	}
`

export const GridItem = styled.div`
	${props => props.theme.breakpoints.down('xl')} {
		width: 200px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		width: 239px;
	}
`

export const FilterButton = styled(Button).attrs({
	fullWidth: true,
})`
	&& {
		margin: 0;
		display: flex;
		flex-direction: row;
		border: 1px solid #89a8cf;
		box-sizing: border-box;
		padding: 2px 12px;
		justify-content: center;
		align-items: center;
		height: 30px;
		color: #89a8cf;
		margin-right: 8px;
	}
`
