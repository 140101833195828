import Container from 'components/Container/Container'
import React from 'react'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { MenuItem } from '@mui/material'
import { useWallet } from '@terra-money/use-wallet'
import { ReactComponent as ChevronDown } from 'theme/icons/chevron-down.svg'
import { uniqBy } from 'lodash'
import { TradeState } from 'utils/blockchain/real/contracts/p2pTrading'
import useNotifyTrades from 'pages/explore/hooks/useNotifyTrades'
import useNotifyCounterTrades from 'pages/explore/hooks/useNotifyCounterTrades'
import { StyledLoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay'
import useVerifiedNFTs from 'hooks/useVerifiedCollections'
import ListingCard from './components/ListingCard/ListingCard'
import {
	BoxesGrid,
	CheckButton,
	ExploreContainer,
	FiltersContainer,
	FilterTitle,
	GridContainer,
	GridItem,
	MainContainer,
	SelectInputContainer,
	Title,
} from './Explore.styled'
import useTrades from './hooks/useTrades'

const statusOptions = [
	{
		label: 'Active',
		value: JSON.stringify([TradeState.Published, TradeState.Countered]),
	},
	{
		label: 'Inactive',
		value: JSON.stringify([TradeState.Cancelled, TradeState.Accepted]),
	},

	{ label: 'Cancelled', value: JSON.stringify([TradeState.Cancelled]) },
	{ label: 'Accepted', value: JSON.stringify([TradeState.Accepted]) },
	{ label: 'Countered', value: JSON.stringify([TradeState.Countered]) },
	// { label: 'Published', value: JSON.stringify([TradeState.Published]) },
]

export default function Explore() {
	const wallet = useWallet()

	const [verifiedCollections] = useVerifiedNFTs()

	// Globally defined.
	useNotifyTrades()
	useNotifyCounterTrades()

	const myAddress = wallet.wallets[0]?.terraAddress

	const [lookingForOptions, setLookingForOptions] = React.useState<
		{ label: string; value: string }[]
	>([])

	const [offeredAsset, setOfferedAsset] = React.useState<string>('none')

	const [wantedNFT, setWantedNFT] = React.useState<string>('none')

	const [status, setStatus] = React.useState<string>(
		statusOptions.find(x => x.label === 'Active')?.value || ''
	)

	const [showMine, setShowMine] = React.useState(false)

	const [showCounteredListings, setShowCounteredListings] = React.useState(false)

	const [showOnlyP2PListings, setShowOnlyP2PListings] = React.useState(false)

	const [trades, loading] = useTrades({
		containsToken: offeredAsset.startsWith('none') ? '' : offeredAsset,
		wantedNFT: wantedNFT.startsWith('none') ? '' : wantedNFT,
		owner: showMine ? wallet.wallets[0]?.terraAddress : '',
		counterer: showCounteredListings ? wallet.wallets[0]?.terraAddress : '',
		states: JSON.parse(status),
		hasWhitelist: showOnlyP2PListings,
	})

	React.useEffect(() => {
		setLookingForOptions(
			verifiedCollections.map(({ collectionAddress, collectionName }) => ({
				label: collectionName,
				value: collectionAddress,
			}))
		)
	}, [verifiedCollections])

	const NFTsOfferedOptions = uniqBy(
		trades
			.flatMap(x => x.cw721s)
			.map(({ contractAddress, collectionName }) => ({
				label: collectionName,
				value: contractAddress,
			})),
		o => o.value
	)

	return (
		<MainContainer>
			<Container style={{ overflow: 'hidden' }}>
				<Title>explore listings</Title>

				<ExploreContainer>
					<BoxesGrid>
						<FiltersContainer>
							<FilterTitle>filter by NFTs offered:</FilterTitle>
						</FiltersContainer>
						<SelectInputContainer
							defaultValue='none'
							value={offeredAsset}
							onChange={e => setOfferedAsset(e.target.value as string)}
							IconComponent={ChevronDown}
						>
							<MenuItem value='none'>Show All</MenuItem>
							{NFTsOfferedOptions.map(({ label, value }) => (
								<MenuItem key={value} value={value}>
									{label}
								</MenuItem>
							))}
						</SelectInputContainer>

						<SelectInputContainer
							defaultValue={statusOptions.find(x => x.label === 'Active')?.value}
							value={status}
							onChange={e => setStatus(e.target.value as string)}
							IconComponent={ChevronDown}
						>
							{statusOptions.map(({ label, value }) => (
								<MenuItem key={value} value={value}>
									{label}
								</MenuItem>
							))}
						</SelectInputContainer>

						<CheckButton
							fullWidth
							checked={showMine}
							onClick={() => setShowMine(!showMine)}
						>
							My Listings
						</CheckButton>

						<CheckButton
							fullWidth
							checked={showCounteredListings}
							onClick={() => setShowCounteredListings(!showCounteredListings)}
						>
							Countered By Me
						</CheckButton>
						<CheckButton
							fullWidth
							checked={showOnlyP2PListings}
							onClick={() => setShowOnlyP2PListings(!showOnlyP2PListings)}
						>
							Only P2P
						</CheckButton>
					</BoxesGrid>
					<VerticalGap gap={8} />
					<BoxesGrid>
						<FiltersContainer>
							<FilterTitle>filter by looking for:</FilterTitle>
						</FiltersContainer>
						<SelectInputContainer
							displayEmpty
							value={wantedNFT}
							defaultValue='none'
							onChange={e => setWantedNFT(e.target.value as string)}
							IconComponent={ChevronDown}
						>
							<MenuItem value='none'>Show All</MenuItem>
							{lookingForOptions.map(({ label, value }) => (
								<MenuItem key={value} value={value}>
									{label}
								</MenuItem>
							))}
						</SelectInputContainer>
					</BoxesGrid>
				</ExploreContainer>
				<StyledLoadingOverlay classNamePrefix='Secondary_' active={loading} spinner>
					<GridContainer>
						{trades
							.map(trade => {
								const isP2P = trade.whitelistedUsers.length > 0
								const isMyP2P =
									trade.whitelistedUsers.includes(myAddress) || trade.owner === myAddress

								if (isP2P && !isMyP2P) {
									return null
								}

								return (
									<GridItem key={trade.tradeId}>
										<ListingCard
											href={`/explore/details/${trade.tradeId}`}
											state={trade.state}
											isMine={trade.isMine}
											verified={trade.cw721s.every(nft =>
												verifiedCollections.some(
													collection => collection.collectionAddress === nft.contractAddress
												)
											)}
											isP2PListing={isP2P}
											lookingForTags={trade?.lookingFor}
											nfts={trade.cw721s}
											funds={trade.funds ?? []}
										/>
									</GridItem>
								)
							})
							.filter(x => x)}
					</GridContainer>
				</StyledLoadingOverlay>
			</Container>
		</MainContainer>
	)
}
