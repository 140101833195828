import styled from 'styled-components'

export const StyledContainer = styled.div`
	display: flex;

	overflow: auto;

	flex: 1;

	${props => props.theme.breakpoints.down('lg')} {
		padding: 24px 24px;
	}

	${props => props.theme.breakpoints.down('md')} {
		padding: 24px 24px;
	}

	${props => props.theme.breakpoints.down('xl')} {
		padding: 24px 24px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		padding: 98px 84px;
	}

	position: relative;
	flex-direction: column;

	${props => props.theme.breakpoints.down('xl')} {
		max-width: 1311px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		max-width: 1511px;
	}
`

export const MainLayoutContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100vh;
`
