import { Button, TextareaAutosize, Input } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
	flex: 1;
`

export const Title = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 42px;
	color: #e5ecf5;

	${props => props.theme.breakpoints.down('xl')} {
		font-size: 34px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		font-size: 42px;
	}
`

export const SectionTitle = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;

	color: #89a8cf;
`

export const TradingCard = styled.div`
	flex-direction: column;
	background: #18283d;
	display: flex;
	padding: 16px;
`

export const StyledTextArea = styled(TextareaAutosize)`
	font-family: Biryani;
	background: rgba(137, 168, 207, 0.2);
	border: 1px solid #89a8cf;
	resize: none;

	font-size: 12px;
	padding: 8px;

	::placeholder {
		opacity: 0.75;
		color: #89a8cf;
		font-size: 14px;
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	&:hover {
		border-radius: 0px;
	}

	color: #89a8cf;
`

export const WalletAddressInput = styled(Input).attrs({
	disableUnderline: true,
})`
	&& {
		background-color: #18283d;
		border: 1px solid #89a8cf;
		color: white;
		padding: 2px 8px;
		padding-top: 5px;

		::placeholder {
			color: blue;
			font-size: 1.5em;
		}
	}
`

export const FooterSection = styled.div`
	display: flex;
	justify-content: center;

	${props => props.theme.breakpoints.down('xl')} {
		padding-top: 10px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		padding-top: 21px;
	}
`

export const StyledButton = styled(Button)<{ disabled?: boolean }>`
	&& {
		padding: 0 12px;
		font-family: 'Pixelade';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		background: linear-gradient(
			89.97deg,
			#88a1c1 -77.6%,
			#3f608c 56.9%,
			#2e5180 88.51%,
			#0b59c4 114.84%
		);

		opacity: ${props => (props.disabled ? 0.5 : 1)};
		box-sizing: border-box;
		color: ${props => (props.disabled ? '#A0BFE4' : '#A0BFE4')};

		&:hover {
			background: linear-gradient(
				89.97deg,
				#88a1c1 -77.6%,
				#3f608c 56.9%,
				#2e5180 88.51%,
				#0b59c4 114.84%
			);
			opacity: ${props => (props.disabled ? 0.5 : 1)};
			color: ${props => (props.disabled ? '#A0BFE4' : '#A0BFE4')};
		}
	}
`
