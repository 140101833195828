import { Select } from '@mui/material'
import styled from 'styled-components'

export const SelectInputContainer = styled(Select)`
	&& {
		margin: 0;
		display: flex;
		flex-direction: row;
		border: 1px solid #89a8cf;
		box-sizing: border-box;
		padding: 2px 12px;
		padding-top: 8px;
		flex: 1;
		align-items: center;
		height: 30px;
		color: #89a8cf;
		border-radius: 0;
	}
`

export const SelectionBox = styled.div`
	padding: 4px 0px;
	padding-left: 24px;
	background: #89a8cf;
	border: 1px solid #89a8cf;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 30px;
	overflow: hidden;
`

export const SelectionBoxText = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: #18283d;
    line-height:16px;
}
`
export const BoxesGrid = styled.div`
	height: 30px;
	display: grid;
	grid-column-gap: 12px;
	grid-row-gap: 8px;

	grid-template-columns: 1fr 1fr 1fr;
`
