import React from 'react'
import { ThemeProvider } from '@mui/material'
import GlobalStyles from 'components/Global.styled'
import Router from 'components/Router'
import { ThemeProvider as SCThemeProvider } from 'styled-components'

import theme from 'theme/theme'
import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import blockchain from 'utils/blockchain/terraUtils'
import { atom } from 'recoil'
import Badge from 'components/Badge/Badge'
import { useMediaQuery } from 'react-responsive'
import RestrictedView from 'components/RestrictedView/RestrictedView'
import { useLocation } from 'react-router-dom'

export const appLoadingState = atom({
	key: 'appLoading',
	default: false,
})

export const filterOptions = atom({
	key: 'filterOptions',
	default: [{ label: 'Show all', value: 'Show all', enabled: true }],
})

export default function App() {
	const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

	const wallet = useWallet()

	const location = useLocation()

	blockchain.setWallet(wallet)

	if (isMobile) {
		return (
			<RestrictedView>
				<Badge color='#ff4444'>SORRY, NOT AVAILABLE FOR MOBILE DEVICES YET.</Badge>
			</RestrictedView>
		)
	}

	return (
		<ThemeProvider theme={theme}>
			<SCThemeProvider theme={theme}>
				{wallet.status === WalletStatus.WALLET_NOT_CONNECTED && (
					<Badge color='#ff4444'>PLEASE CONNECT YOUR WALLET.</Badge>
				)}
				{wallet.network.chainID !== 'bombay-12' &&
					wallet.status === WalletStatus.WALLET_CONNECTED &&
					!location.pathname.includes('/send') && (
						<Badge color='#ff4444'>
							SORRY, THIS FEATURE IS NOT AVAILABLE ON MAINNET YET.
						</Badge>
					)}
				<GlobalStyles />
				<Router />
			</SCThemeProvider>
		</ThemeProvider>
	)
}
