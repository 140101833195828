import React from 'react'
import { IconButton, MenuItem, SelectChangeEvent } from '@mui/material'
import { ReactComponent as CloseIcon } from 'theme/icons/close.svg'
import { ReactComponent as ChevronDown } from 'theme/icons/chevron-down.svg'

import { VerticalGap } from 'components/Layout/Layout.styled'
import { truncateLongString } from 'utils/js/truncateLongText'
import { Collection } from 'hooks/useMyAssets'
import {
	BoxesGrid,
	SelectInputContainer,
	SelectionBox,
	SelectionBoxText,
} from './CollectionsPicker.styled'

interface AssetsPickerProps {
	wantedAssets: string[]
	setWantedAssets: (value: string[]) => void
	maxItems: number
	collections: Collection[]
}

function CollectionsPicker({
	wantedAssets,
	setWantedAssets,
	maxItems,
	collections,
}: AssetsPickerProps) {
	const [availableOptions, setAvailableOptions] = React.useState<
		{ label: string; value: string }[]
	>([])

	const [selectedCollection, setSelectedCollection] = React.useState('"')

	const removeWantedAsset = (value: string) => {
		setWantedAssets(wantedAssets.filter(x => x !== value))
	}

	const onPickedCollection = (e: SelectChangeEvent<unknown>) => {
		if (e.target.value === '""') {
			return
		}
		setWantedAssets([...wantedAssets, `${e.target.value}`])

		setSelectedCollection('"')
	}

	React.useEffect(() => {
		setAvailableOptions(
			collections.map(({ collectionAddress, collectionName }: Collection) => ({
				label: collectionName,
				value: collectionAddress,
			}))
		)
	}, [collections])

	const availableCollections = availableOptions.filter(
		collection =>
			!wantedAssets.includes(collection.value) && wantedAssets.length < maxItems
	)

	return (
		<>
			<BoxesGrid>
				<SelectInputContainer
					value={selectedCollection}
					onChange={onPickedCollection}
					IconComponent={ChevronDown}
				>
					<MenuItem disabled value='"'>
						Add Collection
					</MenuItem>
					{availableCollections.map(({ label, value }) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</SelectInputContainer>
			</BoxesGrid>
			<VerticalGap gap={13} />
			<BoxesGrid>
				{wantedAssets.map(value => (
					<SelectionBox key={value}>
						<SelectionBoxText>
							{truncateLongString(
								availableOptions.find(x => x.value === value)?.label || '',
								14
							)}
						</SelectionBoxText>

						<IconButton
							style={{ marginLeft: 'auto' }}
							onClick={() => removeWantedAsset(value)}
						>
							<CloseIcon fill='#000000' />
						</IconButton>
					</SelectionBox>
				))}
			</BoxesGrid>
		</>
	)
}

export default CollectionsPicker
