import { Button } from '@mui/material'
import styled from 'styled-components'

export const ItemPickerContainer = styled.div`
	flex: 1;
	margin-top: 10px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	grid-column-gap: 8px;
	grid-row-gap: 8px;
`

export const Item = styled(Button)<{ selected: boolean }>`
	&& {
		margin: 0;
		display: flex;
		flex-direction: row;
		border: 1px solid #89a8cf;
		box-sizing: border-box;
		padding: 2px 12px;
		justify-content: center;
		align-items: center;
		height: 30px;
		color: #89a8cf;
		margin-right: 8px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		${props =>
			props.selected
				? `
                color: #23354D;
        		background: #89a8cf;
                border: 1px solid #89a8cf;
                box-sizing: border-box;
                &:hover {
                    background: #89a8cf;
                }
        `
				: ``}
	}
`
