import { Input } from '@mui/material'
import { noop } from 'lodash'
import React from 'react'
import {
	CurrencyInputContainer,
	CurrencyText,
	InputContainer,
} from './CurrencyInput.styled'

interface CurrencyInputProps {
	currency?: string
	onChange?:
		| React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
		| undefined
	value?: unknown
	balance: number
}

function CurrencyInput({
	value,
	onChange,
	currency,
	balance,
}: CurrencyInputProps) {
	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (['-'].includes(event.key)) {
			event.preventDefault()
			event.stopPropagation()
		}
	}

	return (
		<CurrencyInputContainer invalid={Number.parseFloat(value as string) === 0}>
			<InputContainer>
				<Input
					inputProps={{
						style: {
							MozAppearance: 'textfield',
						},
					}}
					onKeyPress={handleKeyPress}
					fullWidth
					type='number'
					value={value}
					onChange={e => {
						const currentValue = e.target.value.replace(',', '.')
						const [digit, decimal] = currentValue.split('.')

						// check if value includes a decimal point
						if (currentValue.match(/\./g)?.length) {
							// restrict value to only 2 decimal places
							if (decimal?.length > 2) {
								// do nothing
								return
							}
						}

						if (currentValue !== '') {
							e.target.value = `${Number.parseInt(digit, 10)}${
								decimal ? `.${decimal}` : ``
							}`
						}

						if (parseFloat(e.target.value) >= balance) {
							e.target.value = Math.floor(balance).toString()
						}

						if (onChange) {
							onChange(e)
						}
					}}
				/>
			</InputContainer>
			<CurrencyText>{currency}</CurrencyText>
		</CurrencyInputContainer>
	)
}

CurrencyInput.defaultProps = {
	onChange: noop,
	value: undefined,
	currency: '',
}

export default CurrencyInput
