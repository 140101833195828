import React from 'react'
import { IconButton } from '@mui/material'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { noop } from 'lodash'
import { NFT } from 'pages/trading/components/TradeCard/TradeCard'
import { ReactComponent as ChevronLeft } from 'theme/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from 'theme/icons/chevron-right.svg'
import { ReactComponent as PeersIcon } from 'theme/icons/users.svg'
import { ReactComponent as Checkbox } from 'theme/icons/checkbox.svg'

import OverflowTip from 'components/OverflowTooltip/OverflowTooltip'
import {
	CardContainer,
	StyledTextArea,
	ContentSection,
	FundBox,
	Image,
	ImageContainer,
	LeftCursorContainer,
	NFTDetailsSection,
	NFTOfferDetails,
	OfferGridContainer,
	OfferInfoSubtitle,
	OfferInfoTitle,
	Overlay,
	RightCursorContainer,
	Subtitle,
	Title,
	TraitBox,
	TraitBoxGrid,
	TraitText,
	ImageOverlay,
	NFTDetailsVerifiedSection,
} from './OfferCard.styled'

interface NFTWithTraits extends Partial<NFT> {
	owner: string
	traits?: [string, string][]
}

export interface OfferCardProps {
	verified: boolean
	isMine?: boolean
	isCounter?: boolean
	hasPrev?: boolean
	hasNext?: boolean
	onPrev?: () => void
	onNext?: () => void
	nft?: NFTWithTraits
	comments?: string
	lookingFor?: string[]
	status: string
	funds: {
		userfacingAmount: any
		userfacingDenom: string
		denom: string
		amount: number
	}[]
	isP2P: boolean
	onTraderCommentsChange?: (value: string) => void
	traderComments?: string
	traderCommentsDisabled?: boolean
	isTrader?: boolean
}

function OfferCard({
	isMine,
	nft,
	comments,
	verified,
	onTraderCommentsChange,
	traderComments,
	traderCommentsDisabled,
	lookingFor,
	status,
	funds,
	isCounter,
	hasPrev,
	hasNext,
	onPrev,
	onNext,
	isTrader,
	isP2P,
}: OfferCardProps) {
	const fundsFormatted = (funds ?? []).map(
		({ userfacingAmount, userfacingDenom }) =>
			`${userfacingAmount} ${userfacingDenom}`
	)

	return (
		<CardContainer isMine={isMine}>
			{isCounter && (
				<Overlay>
					<LeftCursorContainer>
						{hasPrev && (
							<IconButton>
								<ChevronLeft onClick={() => onPrev && onPrev()} />
							</IconButton>
						)}
					</LeftCursorContainer>
					<RightCursorContainer>
						{hasNext && (
							<IconButton>
								<ChevronRight onClick={() => onNext && onNext()} />
							</IconButton>
						)}
					</RightCursorContainer>
				</Overlay>
			)}

			<ImageContainer>
				<ImageOverlay>{isP2P && <PeersIcon fill='#fff' />}</ImageOverlay>
				<Image
					src={nft?.imageUrl || 'https://via.placeholder.com/500/000000/000000/'}
					alt={nft?.name}
				/>
			</ImageContainer>
			<ContentSection>
				<NFTDetailsSection>
					<div style={{ display: 'flex' }}>
						<div>
							<Title>
								<OverflowTip>
									<div>{nft?.collectionName}</div>
								</OverflowTip>
							</Title>
							<Subtitle>
								<OverflowTip>
									<div>{nft?.name}</div>
								</OverflowTip>
							</Subtitle>
						</div>
						<NFTDetailsVerifiedSection>
							{verified && <Checkbox />}
						</NFTDetailsVerifiedSection>
					</div>
					{(nft?.traits ?? []).length > 0 && (
						<>
							<VerticalGap gap={12} />
							<Subtitle>Traits</Subtitle>
							<VerticalGap gap={4} />
							<TraitBoxGrid count={(nft?.traits ?? []).length}>
								{(nft?.traits ?? []).map(([key, value]) => (
									<TraitBox isMine={isMine} key={key}>
										<TraitText>{`${key}:`}</TraitText>
										<OverflowTip>
											<TraitText>{value}</TraitText>
										</OverflowTip>
									</TraitBox>
								))}
							</TraitBoxGrid>
						</>
					)}
				</NFTDetailsSection>
				<NFTOfferDetails>
					<OfferInfoTitle isMine={isMine}>Owner</OfferInfoTitle>
					<VerticalGap gap={4} />
					<OfferInfoSubtitle isMine={isMine}>{nft?.owner}</OfferInfoSubtitle>
					<VerticalGap gap={12} />
					<StyledTextArea
						isTrader={isCounter && isMine}
						value={comments}
						disabled
						minRows={isCounter ? 2 : 4}
					/>
					{isCounter && (
						<>
							<VerticalGap gap={12} />
							<StyledTextArea
								isTrader={isTrader}
								placeholder='Trader comment'
								value={traderComments}
								onChange={e =>
									onTraderCommentsChange && onTraderCommentsChange(e.target.value)
								}
								maxLength={100}
								disabled={traderCommentsDisabled}
								minRows={isCounter ? 2 : 4}
							/>
						</>
					)}
					<VerticalGap gap={12} />
					<OfferGridContainer>
						<Subtitle>{`Status: ${status}`}</Subtitle>
					</OfferGridContainer>
					<VerticalGap gap={4} />
					<OfferGridContainer>
						<Subtitle>Funds </Subtitle>
						{Boolean(fundsFormatted.length) &&
							fundsFormatted.map(fund => (
								<FundBox key={fund}>
									<OverflowTip>
										<div>{fund}</div>
									</OverflowTip>
								</FundBox>
							))}
						{!fundsFormatted.length && <FundBox>-</FundBox>}
					</OfferGridContainer>
					<VerticalGap gap={4} />
					<OfferGridContainer>
						{!isCounter && (
							<>
								<Subtitle>Looking for </Subtitle>

								{Boolean(lookingFor?.length) &&
									lookingFor?.map(tag => (
										<FundBox key={tag}>
											<OverflowTip>
												<div>{tag}</div>
											</OverflowTip>
										</FundBox>
									))}
								{Boolean(!lookingFor?.length) && (
									<FundBox>
										<OverflowTip>
											<div>Not specified</div>
										</OverflowTip>
									</FundBox>
								)}
							</>
						)}
					</OfferGridContainer>
				</NFTOfferDetails>
			</ContentSection>
		</CardContainer>
	)
}

OfferCard.defaultProps = {
	nft: undefined,
	comments: '',
	lookingFor: [],
	isCounter: false,
	hasPrev: false,
	hasNext: false,
	onPrev: noop,
	onNext: noop,
	isMine: false,
	traderCommentsDisabled: true,
	traderComments: '',
	onTraderCommentsChange: noop,
	isTrader: false,
}

export default OfferCard
