import styled from 'styled-components'

export const CurrencyContainer = styled.div`
	margin-top: 54px;
	display: flex;
`

export const Title = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 42px;
	color: #e5ecf5;

	${props => props.theme.breakpoints.down('xl')} {
		font-size: 28px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		font-size: 42px;
	}
`

export const TradingCard = styled.div`
	flex-direction: row;
	background: #18283d;
	display: flex;
	padding: 16px;
`

export const TradingCardContainer = styled.div`
	grid-column-gap: 12px;
	width: 100%;
`

export const ErrorSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 28px;
`

export const ErrorMessage = styled.div``

export const GridContainer = styled.div`
	flex: 1;
	height: 300px;
	width: 100%;
	display: grid;

	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #23354d;
		border-radius: 0px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #566e8e;
		border-radius: 0px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #8caad0;
	}

	grid-column-gap: 12px;
	${props => props.theme.breakpoints.down('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	${props => props.theme.breakpoints.up('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(239px, 1fr));
	}
`

export const CurrenciesContainer = styled.div`
	flex: 1;
	width: 100%;
	display: grid;

	grid-column-gap: 12px;

	${props => props.theme.breakpoints.down('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	${props => props.theme.breakpoints.up('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(239px, 1fr));
	}
`

export const GridItem = styled.div`
	${props => props.theme.breakpoints.down('xl')} {
		width: 200px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		width: 239px;
	}

	width: 239px;
`
