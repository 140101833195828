import React from 'react'
import CurrencyInput from 'components/CurrencyInput/CurrencyInput'
import { useFormikContext } from 'formik'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { TradingForm } from 'pages/trading/Trading'

import { Collection } from 'hooks/useMyAssets'
import {
	CurrenciesContainer,
	ErrorSection,
	GridContainer,
	GridItem,
	Title,
	TradingCard,
	TradingCardContainer,
	ErrorMessage,
} from './WantToTrade.styled'
import TradeCard, { NFT } from '../TradeCard/TradeCard'

export interface SelectedFunds {
	amountUST: string
	amountLuna: string
}

export interface WantToTradeProps {
	funds: {
		balanceUST: number
		balanceLuna: number
	}
	verifiedCollections: Collection[]
}

function WantToTrade({ funds, verifiedCollections }: WantToTradeProps) {
	const { values, errors, setFieldValue } = useFormikContext<TradingForm>()

	const removeNFTFromTrade = (nftToRemove: NFT) => {
		setFieldValue(
			'tradingNFTs',
			values.tradingNFTs.filter(
				(n: NFT) =>
					!(
						n.contractAddress === nftToRemove.contractAddress &&
						n.tokenId === nftToRemove.tokenId
					)
			)
		)
	}

	return (
		<TradingCardContainer>
			<Title>I provide:</Title>
			<VerticalGap gap={12} />
			<TradingCard>
				<div style={{ flex: 1 }}>
					<ErrorSection>
						<ErrorMessage>
							{errors?.tradingNFTs ||
								`${values.tradingNFTs.length} NFT${
									values.tradingNFTs.length > 1 ? 's' : ''
								} selected`}
						</ErrorMessage>
					</ErrorSection>
					<GridContainer>
						{values.tradingNFTs.map((nft: NFT) => (
							<GridItem>
								<TradeCard
									key={JSON.stringify(nft)}
									onRemove={removeNFTFromTrade}
									closable
									verified={verifiedCollections.some(
										collection => collection.collectionAddress === nft.contractAddress
									)}
									nft={nft}
								/>
							</GridItem>
						))}
					</GridContainer>

					<VerticalGap gap={20} />

					<CurrenciesContainer>
						<GridItem>
							<CurrencyInput
								balance={funds.balanceLuna}
								value={values.tradingAmountLuna}
								onChange={e => setFieldValue('tradingAmountLuna', e.target.value)}
								currency='Luna'
							/>
						</GridItem>
						<GridItem>
							<CurrencyInput
								balance={funds.balanceUST}
								value={values.tradingAmountUST}
								onChange={e => setFieldValue('tradingAmountUST', e.target.value)}
								currency='UST'
							/>
						</GridItem>
					</CurrenciesContainer>
				</div>
			</TradingCard>
		</TradingCardContainer>
	)
}

export default WantToTrade
