import React from 'react'
import { HorizontalGap, VerticalGap } from 'components/Layout/Layout.styled'

import { useFormikContext } from 'formik'
import { TradingForm } from 'pages/trading/Trading'

import CollectionsPicker from 'pages/trading/components/LookingFor/components/CollectionsPicker/CollectionsPicker'
import { isValidTerraAddress } from 'utils/blockchain/isValidAddress'
import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import { Collection } from 'hooks/useMyAssets'
import {
	Container,
	FooterSection,
	SectionTitle,
	StyledButton,
	StyledTextArea,
	Title,
	TradingCard,
	WalletAddressInput,
} from './LookingFor.styled'

interface LookingForProps {
	verifiedCollections: Collection[]
}

function LookingFor({ verifiedCollections }: LookingForProps) {
	const wallet = useWallet()

	const { values, setFieldValue, isSubmitting, isValid, submitForm } =
		useFormikContext<TradingForm>()

	const myAddress = wallet.wallets[0]?.terraAddress

	return (
		<Container>
			<Title>I am looking for:</Title>
			<VerticalGap gap={12} />
			<TradingCard>
				<SectionTitle>What assets do you want to trade for?</SectionTitle>
				<VerticalGap gap={13} />
				<CollectionsPicker
					maxItems={3}
					collections={verifiedCollections}
					wantedAssets={values.lookingForAssets}
					setWantedAssets={value => setFieldValue('lookingForAssets', value)}
				/>
				<VerticalGap gap={12} />

				<VerticalGap gap={8} />
				<StyledTextArea
					value={values.lookingForComments}
					onChange={e => setFieldValue('lookingForComments', e.target.value)}
					minRows={4}
					maxRows={4}
					maxLength={100}
					placeholder='Add comments'
				/>
				<VerticalGap gap={16} />

				<SectionTitle>
					list your trade offer for everyone to see or input wallet address to trade
					with chosen peer
				</SectionTitle>
				<VerticalGap gap={4} />

				<WalletAddressInput
					value={values.lookingForWhitelistedAddress}
					onChange={e =>
						setFieldValue('lookingForWhitelistedAddress', e.target.value)
					}
					placeholder='wallet address'
				/>
			</TradingCard>

			<FooterSection>
				{wallet.status === WalletStatus.WALLET_NOT_CONNECTED && (
					<StyledButton onClick={() => wallet.connect()}>
						Connect wallet
					</StyledButton>
				)}
				{wallet.status === WalletStatus.WALLET_CONNECTED && (
					<>
						<>
							<StyledButton
								disabled={
									!isValidTerraAddress(values.lookingForWhitelistedAddress) ||
									values.lookingForWhitelistedAddress === myAddress ||
									isSubmitting ||
									!isValid
								}
								onClick={submitForm}
							>
								Trade with peer
							</StyledButton>
							<HorizontalGap gap={12} />
						</>

						<StyledButton
							disabled={
								!!values.lookingForWhitelistedAddress || isSubmitting || !isValid
							}
							onClick={submitForm}
						>
							List trade offer
						</StyledButton>
					</>
				)}
			</FooterSection>
		</Container>
	)
}

export default LookingFor
