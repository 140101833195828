export const palette = {
	dark: {
		50: '#1E3148',
		100: '#23354D',
	},
	blue: {
		50: '#F3F8FD',
		100: '#D2DEED',
		200: '#C3D3E6',
		300: '#8CAAD0',
		400: '#23354D',
	},
	teal: {
		50: '#4ef1b8',
		200: '#22eda6',
		300: '#1fa174',
		400: '#197b59',
		500: '#255645',
		600: '#1c4537',
	},
	gray: {
		50: '#e4e4e4',
		100: '#999999',
		200: '#666666',
		300: '#474747',
		400: '#383838',
		500: '#292929',
		600: '#212121',
		700: '#1a1a1',
		800: '#121212',
		900: '#0d0e0f',
	},
	secondaryGray: {
		50: '#f4f5f5',
		100: '#e8e9ea',
		200: 'd5d6d8',
		300: '#9ca0ab',
		400: '#717684',
		500: '#595e69',
		600: '#4b4f58',
		700: '#383b42',
		800: '#26282c',
		900: '#131416',
	},
	green: {
		50: '#4FB57C',
		200: '#159D50',
		300: '#145732',
		400: '#1D442E',
	},
	alphaDark: {
		50: 'rgba(33, 33, 33, 0.05)',
		300: 'rgba(33, 33, 33, 0.24)',
		500: 'rgba(33, 33, 33, 0.4)',
		700: 'rgba(33, 33, 33, 0.72)',
		900: 'rgb(33, 33, 33)',
	},
	alphaLight: {
		50: 'rgba(255, 255, 255, 0.04)',
		100: 'rgba(255, 255, 255, 0.11)',
		200: 'rgba(255, 255, 255, 0.18)',
		400: 'rgba(255, 255, 255, 0.32)',
		600: 'rgba(255, 255, 255, 0.52)',
		800: 'rgba(255, 255, 255, 0.88)',
		1000: 'rgba(255, 255, 255, 1)',
	},
	orange: {
		50: '#F2B86D',
		200: '#D38117',
		300: '#7A5625',
		400: '#5B401A',
	},
	red: {
		50: '#EE7A69',
		200: '#E9513A',
		300: '#792F24',
		400: '#54302B',
	},
}
