import styled from 'styled-components'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

export const MainContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
`

export const TabsTitle = styled(Tabs)`
	justify-content: space-around;
`

export const TabTitle = styled(Tab)`
	font-family: 'Pixelade' !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 42px !important;
	color: #e5ecf5 !important;
	height: 40px;
	flex: 0 0 auto !important;
	max-width: none !important;
	gap: 34px !important;
	display: flex;
`

export const ToastLink = styled.a`
	color: #fff;

	&:hover {
		color: #fff;
		opacity: 0.6;
	}
`
