import { useWallet } from '@terra-money/use-wallet'
import React from 'react'
import pMap from 'p-map'
import {
	getAllCounterTrades,
	Trade,
	TradeState,
} from 'utils/blockchain/real/contracts/p2pTrading'
import cw721Contract from 'utils/blockchain/real/contracts/cw721'
import { keysToCamel } from 'utils/js/keysToCamel'
import { amountConverter } from 'utils/blockchain/terraUtils'
import { asyncAction } from 'utils/js/asyncAction'
import { isEmpty, last } from 'lodash'

interface UseCounterTrades {
	states?: TradeState[] | undefined
	owner?: string | undefined
	startAfter?: number | undefined
	limit?: number | undefined
	whitelistedUser?: string
	wantedNFT?: string
	containsToken?: string
}

// TODO: fetch all recursively, this just temporarily, implement infinite scroll later...
const fetchAllCounterTradesUntilEnd = async (
	values: UseCounterTrades
): Promise<Trade[]> => {
	let result: Trade[] = []

	const fetchAllCounterTradesRecursive = async ({
		states,
		owner,
		startAfter,
		limit,
		whitelistedUser,
		wantedNFT,
		containsToken,
	}: UseCounterTrades): Promise<any> => {
		const [error, trades] = await asyncAction(
			getAllCounterTrades(
				states,
				owner,
				startAfter,
				limit,
				whitelistedUser,
				wantedNFT,
				containsToken
			)
		)

		const tradesResponse = trades as Trade[]

		if (error) {
			return tradesResponse
		}

		result = [...result, ...tradesResponse.filter(x => x.tradeInfo)]

		if (tradesResponse.length > 0) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			return fetchAllCounterTradesRecursive({
				states,
				owner,
				startAfter: last(tradesResponse)?.tradeId,
				limit,
				whitelistedUser,
				wantedNFT,
				containsToken,
			})
		}

		return tradesResponse
	}

	await fetchAllCounterTradesRecursive(values)

	return result
}

export default function useCounterTrades({
	states,
	owner,
	whitelistedUser,
	wantedNFT,
	containsToken,
}: UseCounterTrades) {
	const wallet = useWallet()
	const [counterTrades, setCounterTrades] = React.useState<any[]>([])

	const queryCounterTrades = async () => {
		const myAddress = wallet.wallets[0]?.terraAddress

		const tradesResponse = await fetchAllCounterTradesUntilEnd({
			states,
			owner,
			whitelistedUser,
			wantedNFT,
			containsToken,
			limit: 30,
		})

		// TODO: This all heavy data processing should be moved on backend later
		const parsedCounterTrades = await pMap(
			tradesResponse,
			async trade => {
				const cw721s = Object.values(
					trade.tradeInfo.associatedAssets.filter(
						x => x?.cw721Coin && !isEmpty(x?.cw721Coin)
					)
				).flatMap(a => Object.values(a))

				const cw20s = Object.values(
					trade.tradeInfo.associatedAssets.filter(x => x?.cw20 && !isEmpty(x?.cw20))
				).flatMap(a => Object.values(a))

				const cw721sExtended = await pMap(
					cw721s,
					async cw721 => {
						const contractInfo = await cw721Contract.memoizedGetContractInfo(
							cw721.address as string
						)

						const nftInfo = keysToCamel(
							await cw721Contract.memoizedGetNFTInfo(
								cw721.address as string,
								cw721.tokenId as string
							)
						)

						const imageUrl = (nftInfo?.extension?.image ?? '').replace(
							'ipfs://',
							'https://d1mx8bduarpf8s.cloudfront.net/'
						)

						return {
							...cw721,
							collectionName: contractInfo.name,
							...nftInfo,
							name: nftInfo.extension?.name ?? '',
							tokenId: cw721.tokenId,
							contractAddress: cw721.address,
							imageUrl,
						}
					},
					{ concurrency: 10 }
				)
				return {
					...trade,
					cw721s: cw721sExtended,
					cw20s,
					isMine: trade.tradeInfo.owner === myAddress,
					funds: trade.tradeInfo.associatedAssets
						.filter(x => x.coin && !isEmpty(x.coin))
						.map(fund => ({
							...fund,
							userfacingAmount: amountConverter[
								{ uusd: 'ust', uluna: 'luna' }[fund.coin?.denom || ''] || ''
							].blockchainValueToUserFacing(fund.coin?.amount),
							userfacingDenom: { uusd: 'UST', uluna: 'Luna' }[fund.coin?.denom || ''],
						})),
					lookingFor: await pMap(
						trade?.tradeInfo?.additionnalInfo?.nftsWanted ?? [],
						async address => {
							const contractInfo = await cw721Contract.memoizedGetContractInfo(
								address as string
							)

							return contractInfo.name
						},
						{ concurrency: 10 }
					),
				}
			},
			{ concurrency: 10 }
		)

		setCounterTrades(parsedCounterTrades)
	}

	React.useEffect(() => {
		asyncAction(queryCounterTrades())
	}, [
		wallet.network,
		JSON.stringify(states),
		owner,
		whitelistedUser,
		wantedNFT,
		containsToken,
	])

	return [counterTrades]
}
