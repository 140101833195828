import { IconButton } from '@mui/material'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { noop } from 'lodash'
import React from 'react'
import { ReactComponent as CloseIcon } from 'theme/icons/close.svg'
import { ReactComponent as Checkbox } from 'theme/icons/checkbox.svg'
import OverflowTip from 'components/OverflowTooltip/OverflowTooltip'
import {
	CloseContainer,
	DescriptionSection,
	DescriptionText,
	IconSection,
	Image,
	ImageContainer,
	TitleSection,
	TradeCardContainer,
} from './TradeCard.styled'

export interface NFT {
	contractAddress: string
	tokenId: string
	imageUrl: string
	collectionName: string
	name: string
	traits?: [string, string][]
}

export interface NFTCardProps {
	closable?: boolean
	verified: boolean
	nft: NFT
	onRemove?: (nft: NFT) => void
	onClick?: () => void
	disabled?: boolean
}

function TradeCard({
	onRemove,
	closable,
	verified,
	nft,
	onClick,
	disabled,
}: NFTCardProps) {
	return (
		<TradeCardContainer disabled={disabled} onClick={onClick}>
			{closable && (
				<CloseContainer>
					<IconButton onClick={() => onRemove && onRemove(nft)}>
						<CloseIcon fill='#fff' />
					</IconButton>
				</CloseContainer>
			)}

			<ImageContainer>
				<Image
					src={nft?.imageUrl || 'https://via.placeholder.com/500/000000/000000/'}
					alt={nft.name}
				/>
			</ImageContainer>

			<VerticalGap gap={12} />

			<DescriptionSection>
				<IconSection />
				<OverflowTip>
					<TitleSection>
						<div>
							<DescriptionText>{nft.collectionName}</DescriptionText>

							<DescriptionText>{nft.name}</DescriptionText>
						</div>
					</TitleSection>
				</OverflowTip>

				<IconSection>
					{verified && (
						<div>
							<Checkbox />
						</div>
					)}
				</IconSection>
			</DescriptionSection>
		</TradeCardContainer>
	)
}

TradeCard.defaultProps = {
	closable: false,
	onRemove: noop,
	onClick: noop,
	disabled: false,
}

export default TradeCard
