import { Tooltip } from '@mui/material'
import React, {
	useRef,
	useState,
	useEffect,
	ReactElement,
	JSXElementConstructor,
} from 'react'

function OverflowTip({
	children,
}: {
	children: ReactElement<any, string | JSXElementConstructor<any>>
}) {
	const [isOverflowed, setIsOverflow] = useState(false)
	const textElementRef = useRef<any>()
	useEffect(() => {
		setIsOverflow(
			textElementRef.current.scrollWidth > textElementRef.current.clientWidth
		)
	}, [])

	return (
		<Tooltip title={children} disableHoverListener={!isOverflowed}>
			{React.cloneElement(React.Children.only(children), {
				ref: textElementRef,
				style: {
					...children.props.style,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			})}
		</Tooltip>
	)
}

export default OverflowTip
