import styled from 'styled-components'

import { Button, Select } from '@mui/material'

export const MainContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	width: 100%;
`

export const Title = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 42px;
	color: #e5ecf5;
`

export const ExploreContainer = styled.div`
	display: flex;
	flex-direction: column;

	${props => props.theme.breakpoints.down('xl')} {
		margin-top: 12px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		margin-top: 22px;
	}
`

export const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
`

export const FilterTitle = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;

	color: #89a8cf;

	width: 160px;
`

export const GridContainer = styled.div`
	position: absolute;
	inset: 0;
	margin-top: 12px;
	width: 100%;
	display: grid;

	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #23354d;
		border-radius: 0px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #566e8e;
		border-radius: 0px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #8caad0;
	}

	grid-column-gap: 24px;
	grid-row-gap: 30px;

	grid-template-columns: repeat(auto-fill, 239px);
`

export const GridItem = styled.div`
	width: 239px;
`

export const SelectInputContainer = styled(Select)`
	&& {
		margin: 0;
		display: flex;
		flex-direction: row;
		border: 1px solid #89a8cf;
		box-sizing: border-box;
		padding: 2px 12px;
		padding-top: 8px;
		align-items: center;
		height: 30px;
		color: #89a8cf;
		border-radius: 0;
	}
`

export const SelectionBox = styled.div`
	padding: 4px 0px;
	padding-left: 24px;
	background: #89a8cf;
	border: 1px solid #89a8cf;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 30px;
`

export const SelectionBoxText = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: #18283d;
    line-height:16px;
}
`
export const BoxesGrid = styled.div`
	flex: 1;
	display: grid;
	grid-column-gap: 12px;
	grid-row-gap: 12px;

	grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
`

export const CheckButton = styled(Button)<{ checked: boolean }>`
	&& {
		margin: 0;
		font-size: 14px;
		display: flex;
		flex-direction: row;
		border: 1px solid #89a8cf;
		box-sizing: border-box;
		padding: 2px 12px;
		justify-content: center;
		align-items: center;
		height: 30px;
		color: #89a8cf;
		margin-right: 8px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		${props =>
			props.checked
				? `
                color: #23354D;
        		background: #89a8cf;
                border: 1px solid #89a8cf;
                box-sizing: border-box;
                &:hover {
                    background: #89a8cf;
                }
        `
				: ``}
	}
`
