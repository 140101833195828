import styled from 'styled-components'

export const CloseContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 5px;
`

export const LongText = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const TradeCardContainer = styled.div<{ disabled?: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	background: #18283d;
	width: 239px;

	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
	transition: all 0.5s ease-in-out;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
		transform: translate3d(0px, -2px, 0px);
	}

	${props => props.theme.breakpoints.down('xl')} {
		width: 200px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		width: 239px;
	}

	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

export const ImageContainer = styled.div`
	max-width: 240px;
	max-height: 240px;
	overflow: hidden;
`

export const Image = styled.img`
	max-width: 100%;
	max-height: 100%;
`

export const DividerLine = styled.div`
	margin: 7px 0px;
	border: 1px solid #89a8cf;
`

export const IconSection = styled.div`
	width: 50px;
	padding: 0 4px;
`

export const TitleSection = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const DescriptionSection = styled.div`
	display: flex;
`

export const DescriptionText = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	text-align: center;
	color: #e5ecf5;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const TradeCardPreviewGrid = styled.div`
	display: grid;

	${props => props.theme.breakpoints.down('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	}

	${props => props.theme.breakpoints.up('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
	}

	grid-column-gap: 8px;
`

export const PreviewImageContainer = styled.div<{ index?: number }>`
	width: 115px;
`

export const LookingForSection = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 13px;
`

export const LookingForText = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;

	color: #89a8cf;
`

export const LookingForGrid = styled.div`
	margin-top: 8px;

	height: 74px;

	display: grid;

	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

	grid-column-gap: 4px;

	grid-row-gap: 4px;
`

export const LookingForBox = styled.div`
	margin: 0;
	display: flex;
	flex-direction: row;
	border: 1px solid #89a8cf;
	box-sizing: border-box;
	padding: 0px 12px;
	justify-content: center;
	align-items: center;
	height: 24px;
	color: #89a8cf;
	text-align: center;
	font-size: 10px;
`
