import CounterOffer from 'pages/counter-offer/CounterOffer'
import Explore from 'pages/explore/Explore'
import ListingDetails from 'pages/listing-details/ListingDetails'
import Send from 'pages/send/Send'
import Trading from 'pages/trading/Trading'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { MainLayoutContainer } from './Container/Container.styled'
import Sidebar from './Sidebar/Sidebar'

export default function Router() {
	return (
		<MainLayoutContainer>
			<Sidebar />

			<Switch>
				<Route path='/explore' exact component={Explore} />
				<Route path='/explore/details/:tradeId' exact component={ListingDetails} />
				<Route
					path='/explore/details/counter/:tradeId'
					exact
					component={CounterOffer}
				/>

				<Route path='/trade' exact component={Trading} />
				<Route path='/send' exact component={Send} />

				<Redirect to='/explore' />
			</Switch>
		</MainLayoutContainer>
	)
}
