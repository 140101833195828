/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import { ReactComponent as IlliqudityLogo } from 'theme/assets/logo.svg'
import { ReactComponent as WalletIcon } from 'theme/icons/wallet.svg'
import { ReactComponent as DropFull } from 'theme/icons/drop-full.svg'
import { ReactComponent as Sync } from 'theme/icons/sync.svg'
import { ReactComponent as MailArrowRight } from 'theme/icons/mail-arrow-right.svg'
import { ReactComponent as ChartMultiple } from 'theme/icons/chart-multiple.svg'
import { ReactComponent as Coin } from 'theme/icons/coin.svg'
import { ReactComponent as Twitter } from 'theme/icons/twitter.svg'
import { ReactComponent as Discord } from 'theme/icons/discord.svg'
import { ReactComponent as Github } from 'theme/icons/github.svg'
import { ReactComponent as Gitbook } from 'theme/icons/gitbook.svg'
import HeaderOverlayImg from 'theme/assets/header-overlay.png'

import * as ROUTES from 'constants/routes'

import blockchain from 'utils/blockchain/terraUtils'
import { VerticalGap } from 'components/Layout/Layout.styled'

import {
	ButtonContentContainer,
	ConnectionButton,
	ConnectionButtonText,
	HeaderOverlay,
	IconLink,
	LogoContainer,
	NavIconWrapper,
	SectionContainer,
	SectionText,
	SidebarBody,
	SidebarContainer,
	SidebarFooter,
	SidebarHeader,
	SidebarSubheader,
	StyledNavLink,
} from './Sidebar.styled'

export default function Sidebar() {
	const location = useLocation()

	const [openMobile, setOpenMobile] = React.useState(true)

	const { network, status, connect, disconnect } = useWallet()

	const navLinks = [
		{
			name: 'explore',
			href: ROUTES.EXPLORE,
			icon: (props: { fill: string | undefined }) => (
				<DropFull fill={props.fill} />
			),
			disabled: false,
			private: false,
		},
		{
			name: 'trade',
			href: ROUTES.TRADE,
			icon: (props: { fill: string | undefined }) => (
				<Sync width={24} height={24} fill={props.fill} />
			),
			disabled: false,
			private: false,
		},
		{
			name: 'send',
			href: ROUTES.SEND,
			icon: (props: { fill: string | undefined }) => (
				<MailArrowRight fill={props.fill} />
			),
			disabled: false,
			private: false,
		},
	]

	const comingSoon = [
		{
			name: 'analytics',
			href: ROUTES.ANALYTICS,
			disabled: true,
			private: false,
			icon: (props: { fill: string | undefined }) => (
				<ChartMultiple fill={props.fill} />
			),
		},
		{
			name: 'NFT loans',
			href: ROUTES.NFT_LOANS,
			disabled: true,
			private: false,
			icon: (props: { fill: string | undefined }) => <Coin fill={props.fill} />,
		},
	]

	const [, setIsTestnet] = React.useState(false)

	const shouldShowLayoutComponent = (show: boolean) => {
		const layout = document.getElementById('layout-container')

		if (layout) {
			layout.style.display = show ? 'block' : 'none'
		}
	}

	const handleCloseMobile = React.useCallback(() => {
		setOpenMobile(false)
		shouldShowLayoutComponent(true)
	}, [openMobile])

	const handleToggleMobile = React.useCallback(() => {
		setOpenMobile(!openMobile)
		shouldShowLayoutComponent(openMobile)
	}, [openMobile])

	React.useEffect(() => {
		const resize = () => {
			if (window.innerWidth > 991) handleCloseMobile()
		}

		window.addEventListener('resize', resize)

		return () => window.removeEventListener('resize', resize)
	}, [])

	React.useEffect(() => {
		setIsTestnet(blockchain.isTestnet())
	}, [network])

	return (
		<SidebarContainer>
			<HeaderOverlay>
				<img
					style={{ maxWidth: '100%', maxHeight: '100%' }}
					src={HeaderOverlayImg}
					alt=''
				/>
			</HeaderOverlay>
			<SidebarBody>
				<SidebarHeader onClick={handleToggleMobile}>
					<ConnectionButton
						disabled={status === WalletStatus.INITIALIZING}
						onClick={() =>
							status === WalletStatus.WALLET_NOT_CONNECTED ? connect() : disconnect()
						}
					>
						<ButtonContentContainer>
							<WalletIcon />
							<ConnectionButtonText>
								{status === WalletStatus.WALLET_NOT_CONNECTED && 'connect'}
								{status === WalletStatus.INITIALIZING && 'initializing...'}
								{status === WalletStatus.WALLET_CONNECTED && 'disconnect'}
							</ConnectionButtonText>
						</ButtonContentContainer>
					</ConnectionButton>
				</SidebarHeader>
				<SidebarSubheader>
					<Link to={ROUTES.EXPLORE} onClick={handleCloseMobile}>
						<LogoContainer>
							<IlliqudityLogo />
						</LogoContainer>
					</Link>
				</SidebarSubheader>
				<SectionContainer>
					<SectionText>live</SectionText>
					<VerticalGap gap={42} />
					{navLinks.map((link, index) => {
						const isExternal = !link.href.startsWith('/')
						const splittedLink = link.href.split('/')
						const splittedPathname = location.pathname.split('/')

						const isActive =
							(link.href === location.pathname ||
								splittedLink[1] === splittedPathname[1]) &&
							location.pathname !== '/'

						return (
							((link.private && status === WalletStatus.WALLET_CONNECTED) ||
								!link.private) && (
								<StyledNavLink
									// eslint-disable-next-line react/no-array-index-key
									key={`nav-link-${index}`}
									onClick={handleCloseMobile}
									active={isActive}
								>
									{isExternal ? (
										<a href={link.href} target='_blank' rel='noreferrer'>
											{link.name}
										</a>
									) : (
										<>
											<NavIconWrapper>
												{link.icon({ fill: isActive ? '#E5ECF5' : '#89A8CF' })}
											</NavIconWrapper>
											<Link to={link.href}>{link.name}</Link>
										</>
									)}
								</StyledNavLink>
							)
						)
					})}
				</SectionContainer>

				<SectionContainer>
					<SectionText disabled>soon...</SectionText>
					<VerticalGap gap={42} />
					{comingSoon.map((link, index) => {
						const isExternal = !link.href.startsWith('/')
						const splittedLink = link.href.split('/')
						const splittedPathname = location.pathname.split('/')

						const isActive =
							(link.href === location.pathname ||
								splittedLink[1] === splittedPathname[1]) &&
							location.pathname !== '/'

						return (
							((link.private && status === WalletStatus.WALLET_CONNECTED) ||
								!link.private) && (
								<StyledNavLink
									// eslint-disable-next-line react/no-array-index-key
									key={`nav-link-${index}`}
									onClick={handleCloseMobile}
									active={isActive}
									disabled={link.disabled}
								>
									{isExternal ? (
										<a href={link.href} target='_blank' rel='noreferrer'>
											{link.name}
										</a>
									) : (
										<>
											<NavIconWrapper>
												{link.disabled
													? link.icon({ fill: 'rgba(137, 168, 207, 0.44)' })
													: link.icon({ fill: isActive ? '#E5ECF5' : '#89A8CF' })}
											</NavIconWrapper>
											<Link
												{...(link.disabled ? { onClick: e => e.preventDefault() } : {})}
												to={link.href}
											>
												{link.name}
											</Link>
										</>
									)}
								</StyledNavLink>
							)
						)
					})}
				</SectionContainer>
				<SidebarFooter>
					<IconLink href={ROUTES.TWITTER} target='_blank' rel='noreferrer'>
						<Twitter />
					</IconLink>
					<IconLink href={ROUTES.DISCORD} target='_blank' rel='noreferrer'>
						<Discord />
					</IconLink>
					<IconLink href={ROUTES.GITHUB} target='_blank' rel='noreferrer'>
						<Github />
					</IconLink>
					<IconLink href={ROUTES.GITBOOK} target='_blank' rel='noreferrer'>
						<Gitbook />
					</IconLink>
				</SidebarFooter>
			</SidebarBody>
		</SidebarContainer>
	)
}
