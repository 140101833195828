import styled from 'styled-components'
import { Input, Button } from '@mui/material'

export const CurrencyContainer = styled.div`
	margin-top: 54px;
	display: flex;
`

export const Title = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 42px;
	color: #e5ecf5;
	margin-right: 10px;

	${props => props.theme.breakpoints.down('xl')} {
		font-size: 28px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		font-size: 42px;
	}
`

export const TradingCard = styled.div`
	flex-direction: row;
	background: #18283d;
	display: flex;
	padding: 16px;
`

export const OptionsCard = styled(TradingCard)`
	flex-direction: row;
	align-items: center;
	gap: 24px;
	flex: 1;
`

export const SendActionsContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: 18px;
	justify-content: center;
`

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	grid-column-gap: 12px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`

export const ErrorSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 28px;
`

export const ErrorMessage = styled.div``

export const GridContainer = styled.div`
	flex: 1;
	height: 300px;
	width: 100%;
	display: grid;

	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #23354d;
		border-radius: 0px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #566e8e;
		border-radius: 0px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #8caad0;
	}

	grid-column-gap: 12px;
	${props => props.theme.breakpoints.down('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	${props => props.theme.breakpoints.up('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(239px, 1fr));
	}
`

export const CurrenciesContainer = styled.div`
	flex: 1;
	width: 100%;
	display: grid;

	grid-column-gap: 12px;

	${props => props.theme.breakpoints.down('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	${props => props.theme.breakpoints.up('xl')} {
		grid-template-columns: repeat(auto-fill, minmax(239px, 1fr));
	}
`

export const GridItem = styled.div`
	${props => props.theme.breakpoints.down('xl')} {
		width: 200px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		width: 239px;
	}

	width: 239px;
`

export const WalletAddressInput = styled(Input).attrs({
	disableUnderline: true,
})`
	&& {
		background-color: #18283d;
		border: 1px solid #89a8cf;
		color: white;
		padding: 2px 8px;
		padding-top: 5px;
		width: 100%;

		::placeholder {
			color: blue;
			font-size: 1.5em;
		}
	}
`

export const StyledButton = styled(Button)<{ disabled?: boolean }>`
	&& {
		padding: 0 12px;
		font-family: 'Pixelade';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		background: linear-gradient(
			89.97deg,
			#88a1c1 -77.6%,
			#3f608c 56.9%,
			#2e5180 88.51%,
			#0b59c4 114.84%
		);
		flex-shrink: 0;
		opacity: ${props => (props.disabled ? 0.5 : 1)};
		box-sizing: border-box;
		color: ${props => (props.disabled ? '#A0BFE4' : '#A0BFE4')};

		&:hover {
			background: linear-gradient(
				89.97deg,
				#88a1c1 -77.6%,
				#3f608c 56.9%,
				#2e5180 88.51%,
				#0b59c4 114.84%
			);
			opacity: ${props => (props.disabled ? 0.5 : 1)};
			color: ${props => (props.disabled ? '#A0BFE4' : '#A0BFE4')};
		}
	}
`
