import React from 'react'
import { useFormikContext } from 'formik'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { TradingForm } from 'pages/trading/Trading'

import { Collection } from 'hooks/useMyAssets'
import {
	ErrorSection,
	GridContainer,
	GridItem,
	TradingCard,
	TradingCardContainer,
	ErrorMessage,
} from './WantToSend.styled'
import TradeCard, { NFT } from '../../../trading/components/TradeCard/TradeCard'

interface WantToSendProps {
	verifiedCollections: Collection[]
}

function WantToSend({ verifiedCollections }: WantToSendProps) {
	const { values, errors, setFieldValue } = useFormikContext<TradingForm>()

	const removeNFTFromTrade = (nftToRemove: NFT) => {
		setFieldValue(
			'tradingNFTs',
			values.tradingNFTs.filter(
				(n: NFT) =>
					!(
						n.contractAddress === nftToRemove.contractAddress &&
						n.tokenId === nftToRemove.tokenId
					)
			)
		)
	}

	return (
		<TradingCardContainer>
			<VerticalGap gap={12} />
			<TradingCard>
				<div style={{ flex: 1 }}>
					<ErrorSection>
						<ErrorMessage>
							{errors?.tradingNFTs ||
								`${values.tradingNFTs.length} NFT${
									values.tradingNFTs.length > 1 ? 's' : ''
								} selected`}
						</ErrorMessage>
					</ErrorSection>
					<GridContainer>
						{values.tradingNFTs.map((nft: NFT) => (
							<GridItem>
								<TradeCard
									key={JSON.stringify(nft)}
									onRemove={removeNFTFromTrade}
									closable
									verified={verifiedCollections.some(
										collection => collection.collectionAddress === nft.contractAddress
									)}
									nft={nft}
								/>
							</GridItem>
						))}
					</GridContainer>

					<VerticalGap gap={20} />
				</div>
			</TradingCard>
		</TradingCardContainer>
	)
}

export default WantToSend
