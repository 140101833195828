import { HorizontalGap, VerticalGap } from 'components/Layout/Layout.styled'
import { isNil } from 'lodash'
import { TradeResponse } from 'pages/listing-details/hooks/useTradeInfo'
import { NFT } from 'pages/trading/components/TradeCard/TradeCard'
import React from 'react'
import moment from 'moment'
import {
	acceptTrade,
	cancelCounterTradeAndWithdraw,
	confirmCounterTrade,
	refuseCounterTrade,
	removeAllFromCreatedCounterTrade,
	withdrawAcceptedTrade,
	withdrawAllFromCounter,
} from 'utils/blockchain/real/contracts/p2pTrading'
import { Collection } from 'hooks/useMyAssets'
import OfferCard from '../OfferCard/OfferCard'
import {
	ListingCardDescriptionSection,
	ListingText,
	MiddleRightActions,
	MiddleSection,
	PreviewGrid,
	PreviewImage,
	PreviewImageContainer,
	StyledButton,
	Title,
} from './CounterOffers.styled'

interface CounterOffersProps {
	verifiedCollections: Collection[]
	counterTrades: TradeResponse[]
	tradeInfo: TradeResponse | null
	setCounterTrade: (counterTrade: TradeResponse | null) => void
	counterTrade: TradeResponse | null
	executeTransaction: (promise: Promise<unknown>) => Promise<void>
}

function CounterOffers({
	counterTrades,
	tradeInfo,
	setCounterTrade,
	counterTrade,
	executeTransaction,
	verifiedCollections,
}: CounterOffersProps) {
	const [traderComments, setTraderComments] = React.useState('')

	const [previewCounterNFT, setPreviewCounterNFT] = React.useState<NFT | null>(
		null
	)

	React.useEffect(() => {
		const [cTrade] = counterTrades ?? []

		setCounterTrade(cTrade)

		const [nft] = cTrade?.cw721s ?? []

		setPreviewCounterNFT(nft ?? null)
	}, [counterTrades])

	React.useEffect(() => {
		setTraderComments(counterTrade?.additionnalInfo?.traderComment?.comment ?? '')
	}, [counterTrade])

	if (tradeInfo && counterTrades?.length > 0 && counterTrade) {
		const counterTradeIndex = counterTrades.findIndex(
			ct => ct?.counterId === counterTrade?.counterId
		)

		const hasNext =
			counterTradeIndex > -1 && counterTradeIndex < counterTrades.length - 1

		const hasPrev = counterTradeIndex > 0

		const setPreviewCounterTrade = (cTrade: TradeResponse | null) => {
			setCounterTrade(cTrade)

			const [nft] = cTrade?.cw721s ?? []

			setPreviewCounterNFT(nft ?? null)
		}

		const offeredDate = moment(
			Math.round(+(counterTrade?.additionnalInfo?.time ?? 0) / 1000000)
		).format('DD.MM.YYYY')

		const currentListingCount = counterTradeIndex + 1

		return (
			<>
				<VerticalGap gap={48} />
				<Title>Offers for this listing:</Title>
				<ListingCardDescriptionSection>
					<ListingText>
						{`${currentListingCount} out of ${counterTrades?.length} offer${
							counterTrades?.length > 1 ? 's' : ''
						}`}
					</ListingText>
					<ListingText>{`Offered: ${offeredDate}`}</ListingText>
				</ListingCardDescriptionSection>
				<OfferCard
					verified={verifiedCollections.some(
						collection =>
							collection.collectionAddress === previewCounterNFT?.contractAddress
					)}
					isTrader={tradeInfo.isMine}
					traderComments={traderComments}
					onTraderCommentsChange={(value: string) => setTraderComments(value)}
					isP2P={false}
					isCounter
					isMine={counterTrade?.isMine}
					hasNext={hasNext}
					hasPrev={hasPrev}
					onNext={() =>
						hasNext && setPreviewCounterTrade(counterTrades[counterTradeIndex + 1])
					}
					onPrev={() =>
						hasPrev && setPreviewCounterTrade(counterTrades[counterTradeIndex - 1])
					}
					traderCommentsDisabled={
						counterTrade?.isMine ||
						!tradeInfo?.isMine ||
						counterTrade?.state !== 'published'
					}
					funds={counterTrade?.funds ?? []}
					status={counterTrade?.state ?? ''}
					comments={counterTrade?.additionnalInfo?.ownerComment?.comment ?? ''}
					lookingFor={counterTrade?.lookingFor ?? []}
					nft={{
						...previewCounterNFT,
						owner: counterTrade?.owner ?? '',
					}}
				/>
				<MiddleSection>
					<div style={{ flex: 1 }}>
						<VerticalGap gap={8} />
						<PreviewGrid>
							{counterTrade?.cw721s.map(nft => (
								<PreviewImageContainer
									disabled={JSON.stringify(previewCounterNFT) === JSON.stringify(nft)}
									key={JSON.stringify(nft)}
									onClick={() => setPreviewCounterNFT(nft)}
								>
									<PreviewImage
										src={
											nft?.imageUrl || 'https://via.placeholder.com/500/000000/000000/'
										}
										alt={nft?.name}
									/>
								</PreviewImageContainer>
							))}
						</PreviewGrid>
					</div>
					<MiddleRightActions>
						<div>
							<VerticalGap gap={8} />
							{/* When trade is countered and I am the offerer and counter trade is
						published. 
							* I can review trade offer.
							* I can refuse counter trade.
							* I can accept trade offer.
						*/}
							<div style={{ display: 'flex' }}>
								{tradeInfo?.isMine &&
									['countered'].includes(tradeInfo?.state) &&
									!counterTrade?.isMine &&
									['published'].includes(counterTrade?.state) && (
										<>
											<StyledButton
												onClick={async () => {
													if (isNil(tradeInfo?.tradeId) || isNil(counterTrade?.counterId)) {
														return
													}
													const withdraw = true // Auto withdraw on accept
													await executeTransaction(
														acceptTrade(
															counterTrade?.counterId,
															tradeInfo.tradeId,
															traderComments,
															withdraw
														)
													)
												}}
											>
												Accept Offer
											</StyledButton>
											<HorizontalGap gap={8} />

											<StyledButton
												onClick={async () => {
													if (isNil(tradeInfo?.tradeId) || isNil(counterTrade?.counterId)) {
														return
													}

													await executeTransaction(
														refuseCounterTrade(
															tradeInfo.tradeId,
															counterTrade?.counterId,
															traderComments
														)
													)
												}}
											>
												Refuse Offer
											</StyledButton>
											<HorizontalGap gap={8} />
										</>
									)}

								{tradeInfo?.isMine &&
									['accepted'].includes(tradeInfo?.state) &&
									!counterTrade?.isMine &&
									['accepted'].includes(counterTrade?.state) &&
									!counterTrade?.assetsWithdrawn && (
										<StyledButton
											onClick={async () => {
												if (isNil(tradeInfo?.tradeId) || isNil(counterTrade?.counterId)) {
													return
												}
												await executeTransaction(withdrawAcceptedTrade(tradeInfo.tradeId))
											}}
										>
											Withdraw
										</StyledButton>
									)}

								{/* When trade is not mine, in any state, my counter trade is published I can
								 * Cancel and withdraw from counter trade.
								 */}
								{!tradeInfo?.isMine &&
									counterTrade?.isMine &&
									['published'].includes(counterTrade?.state) && (
										<StyledButton
											onClick={async () => {
												if (isNil(tradeInfo?.tradeId) || isNil(counterTrade?.counterId)) {
													return
												}
												await executeTransaction(
													cancelCounterTradeAndWithdraw(
														counterTrade?.counterId,
														tradeInfo.tradeId
													)
												)
											}}
										>
											Cancel Offer
										</StyledButton>
									)}

								{/* 
								When trade is not mine, trade is any state, my counter trade is refused (not accepted) or cancelled I can
						 			* Withdraw my assets from counter trade.
								*/}
								{!tradeInfo?.isMine &&
									counterTrade?.isMine &&
									!counterTrade?.assetsWithdrawn &&
									['refused', 'cancelled'].includes(counterTrade?.state) && (
										<StyledButton
											onClick={async () => {
												if (isNil(tradeInfo?.tradeId) || isNil(counterTrade?.counterId)) {
													return
												}
												await executeTransaction(
													withdrawAllFromCounter(tradeInfo.tradeId, counterTrade?.counterId)
												)
											}}
										>
											Withdraw
										</StyledButton>
									)}
								{/*
									When trade is in created state, I can withdraw my assets or I can publish
								*/}
								{!tradeInfo?.isMine &&
									counterTrade?.isMine &&
									!counterTrade?.assetsWithdrawn &&
									['created'].includes(counterTrade?.state) && (
										<StyledButton
											onClick={async () => {
												if (isNil(tradeInfo?.tradeId) || isNil(counterTrade?.counterId)) {
													return
												}
												await executeTransaction(
													removeAllFromCreatedCounterTrade(
														counterTrade?.counterId,
														tradeInfo?.tradeId
													)
												)
											}}
										>
											Withdraw
										</StyledButton>
									)}
								{/*
									When trade is in created state and not accepted I can publish
								*/}

								{!tradeInfo?.isMine &&
									counterTrade?.isMine &&
									!counterTrade?.assetsWithdrawn &&
									!['accepted'].includes(tradeInfo?.state) &&
									['created'].includes(counterTrade?.state) && (
										<>
											<HorizontalGap gap={8} />
											<StyledButton
												onClick={async () => {
													if (isNil(tradeInfo?.tradeId) || isNil(counterTrade?.counterId)) {
														return
													}
													await executeTransaction(
														confirmCounterTrade(counterTrade?.counterId, tradeInfo?.tradeId)
													)
												}}
											>
												Publish
											</StyledButton>
										</>
									)}
							</div>
						</div>
					</MiddleRightActions>
				</MiddleSection>
			</>
		)
	}

	return null
}

export default CounterOffers
