import terraUtils from '../terraUtils'

export type ContractName = 'p2p-trade' | 'Illiqudity' | 'fee-collector'

const cw20Addresses: any = {
	'columbus-5': {
		Illiqudity: '',
	},
	'bombay-12': {
		Illiqudity: 'terra1pjl5ahnrl4xs7cxjlccmk7flu9ceh8ulk6r30g',
	},
}

const addresses: any = {
	'columbus-5': {
		'p2p-trade': '',
		'fee-collector': '',
		...cw20Addresses['columbus-5'],
	},
	'bombay-12': {
		'p2p-trade': 'terra1xj0d6qlx5qepwy54n0ekhqmlxp5zltwrqj9kkl',
		'fee-collector': 'terra1gjaesp2pd2ef7uf68h76yvpdz74xv0e0n92ly7',
		...cw20Addresses['bombay-12'],
	},
}

function getContractAddress(contractName: ContractName): string {
	const networkId = terraUtils.getNetworkId()
	return addresses[networkId][contractName]
}

export default {
	getContractAddress,
}
