import React from 'react'
import { useFormikContext } from 'formik'
import { useRecoilState } from 'recoil'
import { filterOptions } from 'App'
import { TradingForm } from 'pages/trading/Trading'
import { StyledLoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay'
import { Collection } from '../../../../hooks/useMyAssets'
import {
	Card,
	Container,
	GridContainer,
	GridItem,
	SectionTitle,
} from './YourNFTs.styled'
import TradeCard, { NFT } from '../TradeCard/TradeCard'
import OptionsPicker from '../../../../components/OptionsPicker/OptionsPicker'

interface YourNFTsProps {
	nfts: NFT[]
	collections: Collection[]
	isLoading: boolean
	verifiedCollections: Collection[]
}

function YourNFTs({
	nfts,
	collections,
	isLoading,
	verifiedCollections,
}: YourNFTsProps) {
	const { values, setFieldValue } = useFormikContext<TradingForm>()

	const collectionsOptions = React.useMemo(
		() =>
			collections.map(collection => ({
				label: collection?.collectionName,
				value: collection?.collectionAddress,
				enabled: true,
			})),
		[collections]
	)

	const [filterOptionsValue, setFilterOptions] = useRecoilState(filterOptions)

	React.useEffect(() => {
		setFilterOptions(
			[
				{ label: 'Show all', value: 'Show all', enabled: true },
				...collectionsOptions,
			].map(col => ({
				...col,
				enabled:
					filterOptionsValue.find(f => f.value === col.value)?.enabled ??
					col.enabled,
			}))
		)
	}, [collectionsOptions])

	const filteredNFTs = React.useMemo(
		() =>
			nfts.filter(nft =>
				filterOptionsValue
					.filter(x => x.enabled)
					.map(x => x.value)
					.includes(nft.contractAddress)
			),
		[nfts, filterOptionsValue]
	)

	const selectNFT = (nft: NFT) => {
		if (values.tradingNFTs.some(x => JSON.stringify(x) === JSON.stringify(nft))) {
			setFieldValue(
				'tradingNFTs',
				values.tradingNFTs.filter(x => JSON.stringify(x) !== JSON.stringify(nft))
			)

			return
		}

		setFieldValue('tradingNFTs', [...values.tradingNFTs, nft])
	}

	return (
		<Container>
			<Card>
				<SectionTitle>Your NFTs:</SectionTitle>
				<OptionsPicker options={filterOptionsValue} setOptions={setFilterOptions} />
				<StyledLoadingOverlay
					classNamePrefix='Secondary_'
					active={isLoading}
					spinner
				>
					<GridContainer>
						{filteredNFTs.map(nft => (
							<GridItem key={`${nft.contractAddress}_${nft.tokenId}_${nft.name}`}>
								<TradeCard
									onClick={() => selectNFT(nft)}
									disabled={values.tradingNFTs.some(
										x => JSON.stringify(x) === JSON.stringify(nft)
									)}
									verified={verifiedCollections.some(
										collection => collection.collectionAddress === nft.contractAddress
									)}
									nft={nft}
								/>
							</GridItem>
						))}
					</GridContainer>
				</StyledLoadingOverlay>
			</Card>
		</Container>
	)
}

export default YourNFTs
