import Container from 'components/Container/Container'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { NFT } from 'pages/trading/components/TradeCard/TradeCard'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
	cancelAndWithdrawTrade,
	withdrawPendingAssets,
} from 'utils/blockchain/real/contracts/p2pTrading'
import { ReactComponent as Sync } from 'theme/icons/sync.svg'
import { useRecoilState } from 'recoil'
import { appLoadingState } from 'App'
import { TxReceipt } from 'utils/blockchain/blockchain.interface'
import useBroadcastingTx from 'hooks/useBroadcastingTx'
import { asyncAction } from 'utils/js/asyncAction'
import useMyAssets from 'hooks/useMyAssets'
import { isNil } from 'lodash'
import moment from 'moment'
import useTransactionError from 'hooks/useTransactionError'
import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import { StyledLoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay'
import useVerifiedNFTs from 'hooks/useVerifiedCollections'
import OfferCard from './components/OfferCard/OfferCard'
import useTradeInfo, { TradeResponse } from './hooks/useTradeInfo'

import {
	MainContainer,
	Title,
	ListingDetailsContainer,
	PreviewGrid,
	PreviewImage,
	PreviewImageContainer,
	MiddleSection,
	MiddleContainer,
	MiddleRightActions,
	StyledButton,
	ListingTimeSection,
	ListingTimeDescription,
} from './ListingDetails.styled'
import CounterOffers from './components/CounterOffers/CounterOffers'

export default function ListingDetails() {
	const [verifiedCollections] = useVerifiedNFTs()
	const [showTransactionError] = useTransactionError()
	const history = useHistory()
	const wallet = useWallet()

	const [counterTrade, setCounterTrade] = React.useState<TradeResponse | null>(
		null
	)
	const { fetchMyAssets } = useMyAssets()

	const [appLoading, setAppLoading] = useRecoilState(appLoadingState)

	const match = useRouteMatch<{ tradeId: string }>()

	const tradeId = +match.params.tradeId

	const [tradeInfo, counterTrades, fetchTradeInfo] = useTradeInfo(tradeId)

	const [previewNFT, setPreviewNFT] = React.useState<NFT | null>(null)

	const [txReceipt, setTxReceipt] = React.useState<TxReceipt | null>(null)

	const onSuccessBroadcast = async () => {
		await fetchMyAssets()
		await fetchTradeInfo()
		setAppLoading(false)
	}

	const { setLoading, loading } = useBroadcastingTx(
		txReceipt?.txId,
		onSuccessBroadcast
	)

	const executeTransaction = async (promise: Promise<unknown>) => {
		setAppLoading(true)
		setLoading({ ...loading, send: true })

		const [error, txResponse] = await asyncAction(promise)

		if (txResponse) {
			setTxReceipt(txResponse)
		}

		if (error) {
			showTransactionError(error)
			setAppLoading(false)
		}
		setLoading({ ...loading, send: false })
	}

	const prepareTradeAssets = async () => {
		setAppLoading(true)

		const [nft] = tradeInfo?.cw721s ?? []

		setPreviewNFT(nft ?? null)

		await fetchMyAssets()
	}

	React.useEffect(() => {
		prepareTradeAssets()
	}, [tradeInfo])

	const onMakeOffer = () => {
		history.push(`/explore/details/counter/${tradeId}`)
	}

	const isP2P = (tradeInfo?.whitelistedUsers ?? []).length > 0

	const offeredDate = moment(
		Math.round(+(tradeInfo?.additionnalInfo?.time ?? 0) / 1000000)
	).format('DD.MM.YYYY')

	return (
		<StyledLoadingOverlay
			classNamePrefix='Primary_'
			flex
			active={appLoading}
			spinner
		>
			<MainContainer>
				<Container>
					<Title>view listing details</Title>
					<ListingDetailsContainer>
						<ListingTimeSection>
							<ListingTimeDescription>{`Listed since: ${offeredDate}`}</ListingTimeDescription>
						</ListingTimeSection>
						<OfferCard
							verified={verifiedCollections.some(
								collection =>
									collection.collectionAddress === previewNFT?.contractAddress
							)}
							isP2P={isP2P}
							isMine={tradeInfo?.isMine}
							funds={tradeInfo?.funds ?? []}
							status={tradeInfo?.state ?? ''}
							comments={tradeInfo?.additionnalInfo?.ownerComment?.comment ?? ''}
							lookingFor={tradeInfo?.lookingFor ?? []}
							nft={{
								...previewNFT,
								owner: tradeInfo?.owner ?? '',
							}}
						/>
						<MiddleSection>
							<div style={{ flex: 1 }}>
								<VerticalGap gap={8} />
								<PreviewGrid>
									{tradeInfo?.cw721s.map(nft => (
										<PreviewImageContainer
											disabled={JSON.stringify(previewNFT) === JSON.stringify(nft)}
											key={JSON.stringify(nft)}
											onClick={() => setPreviewNFT(nft)}
										>
											<PreviewImage
												src={
													nft?.imageUrl || 'https://via.placeholder.com/500/000000/000000/'
												}
												alt={nft?.name}
											/>
										</PreviewImageContainer>
									))}
								</PreviewGrid>
							</div>
							<MiddleContainer>
								<div style={{ transform: 'rotate(-90deg)' }}>
									<Sync width={48} height={48} fill='#89A8CF' />
								</div>
							</MiddleContainer>
							<MiddleRightActions>
								<div>
									<VerticalGap gap={8} />
									{wallet.status === WalletStatus.WALLET_NOT_CONNECTED && (
										<StyledButton onClick={() => wallet.connect()}>
											Connect wallet
										</StyledButton>
									)}
									{/* Counter trader can make offer on trade if trade is published and countered */}
									{tradeInfo &&
										!tradeInfo?.isMine &&
										wallet.status === WalletStatus.WALLET_CONNECTED &&
										['published', 'countered'].includes(tradeInfo?.state) && (
											<StyledButton onClick={onMakeOffer}>make offer</StyledButton>
										)}
									{/* Trader can cancel his trade and withdraw funds in one go in and of states create/published/countered */}
									{tradeInfo &&
										tradeInfo?.isMine &&
										['created', 'published', 'countered'].includes(tradeInfo?.state) && (
											<StyledButton
												onClick={async () =>
													executeTransaction(cancelAndWithdrawTrade(tradeId))
												}
											>
												Cancel Listing
											</StyledButton>
										)}
									{/* Case when counterer withdraws my trade, after accepting. It is more logical to be under top card. */}
									{tradeInfo &&
										!tradeInfo?.isMine &&
										['accepted'].includes(tradeInfo?.state) &&
										counterTrade?.isMine &&
										!tradeInfo?.assetsWithdrawn &&
										['accepted'].includes(counterTrade?.state) && (
											<StyledButton
												onClick={async () => {
													if (isNil(tradeInfo?.tradeId) || isNil(counterTrade?.counterId)) {
														return
													}

													await executeTransaction(withdrawPendingAssets(tradeInfo.tradeId))
												}}
											>
												Withdraw Accepted
											</StyledButton>
										)}
								</div>
							</MiddleRightActions>
						</MiddleSection>

						<CounterOffers
							verifiedCollections={verifiedCollections}
							counterTrade={counterTrade}
							setCounterTrade={setCounterTrade}
							counterTrades={counterTrades}
							tradeInfo={tradeInfo}
							executeTransaction={executeTransaction}
						/>
						<VerticalGap gap={40} />
					</ListingDetailsContainer>
				</Container>
			</MainContainer>
		</StyledLoadingOverlay>
	)
}
