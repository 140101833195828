import styled from 'styled-components'

export const CurrencyInputContainer = styled.div<{ invalid?: boolean }>`
	display: flex;
	flex-direction: row;
	border: 1px solid ${props => (props.invalid ? '#E9513A' : '#89a8cf')};
	box-sizing: border-box;
	padding: 6px 24px;
	flex: 1;
	justify-content: space-between;
	align-items: flex-end;
	height: 45px;
`

export const InputContainer = styled.div`
	flex: 1;
	margin-right: 12px;
`

export const CurrencyText = styled.div`
	padding-top: 8px;
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: #89a8cf;
`
